import React from 'react'

const privacyEn = () => {
  return (
    <div className="office-wrapper" style={{padding: "4rem 0 0 0"}}>
        <section className='privacy-section'>
            <h1>Clinic Unlock Privacy Policy</h1>
            <h4>Clinic Unlock APPS, ADD-INS AND EXTENSIONS PRIVACY STATEMENT</h4>
            <p>At clinicunlock.com, trust and transparency are one main values . The Clinic Unlock mobile app, add-in or browser extension (the “App”) that links to this Privacy Statement helps our customers to manage the Services we offer, as well as their businesses and customer relationships on the go.</p>
            <h4>Personal Data and Customer Data</h4>
            <p>Personal Data and other data submitted by or on behalf of a Customer to the Services through the App is considered by Clinic Unlock to be "Customer Data". As between the Customer and Clinic Unlock, each Customer is the controller of its "Customer Data" and Clinic Unlock does not process that Customer Data by any means.</p>
            <h4>Deleting Customer Data</h4>
            <p>Any user of our App can delete his "Customer Data" at any point during his active period or after the account expiry. If "Customer Data" is not deleted by the User within 90 days from expiry, Clinic Unlock can clear permanently all the accounts "Customer Data" without holding any backups.</p>
            <h3>WHAT DATA DO WE COLLECT?</h3>
            <h4>Personal Data Collected Directly From You</h4>
            <p>In connection with your download and use of the App, we may collect certain account information, such as your name, username, email address, physical address, phone number, date of birth and job title, and other information that you provide.</p>
            <p>We might use that Personal Data to enhance customer and marketing experience.</p>
            <h4>How We Secure Your Personal or Customer Data</h4>
            <p>Clinic Unlock takes reasonable and appropriate steps to protect the security of Personal Data against unauthorized or unlawful access, use, destruction, loss, alteration or disclosure.</p>
        </section>
    </div>
  )
}

export default privacyEn