import React from 'react';


const AccordianSection = () => {
    return (
        <section className='product-faq-section arabic'>
            <div className='faq-wrapper'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12 p-0'>
                            <h2 className='faq-heading clinic-hs-2 fw-6'>الأسئلة الشائعة</h2>
                            <div className='accordion' id='accordionFAQ'>
                                <div className='accordion-item'>
                                    <h2 className='accordion-header' id='headingOne'>
                                    <button className='accordion-button clinic-fs-4 fw-5' type='button' data-bs-toggle='collapse' data-bs-target='#collapseOne' aria-expanded='true' aria-controls='collapseOne'>
                                        كم من الوقت يستغرق إنهاء تطبيق مخصص لعيادتي؟
                                    </button>
                                    </h2>
                                    <div id='collapseOne' className='accordion-collapse collapse show' aria-labelledby='headingOne' data-bs-parent='#accordionFAQ'>
                                    <div className='accordion-body clinic-fs-5 fw-3'>
                                        عادة ما تكون عملية التنفيذ أسرع بعشر مرات من أي شيء اخر ولها توصيل مضمون بنسبة 100%. بعد تحديد مخطط المشروع وتوقيع العقد، سيستغرق حتى 4 أسابيع لإنهاء تطوير وتنفيذ التطبيق.
                                    </div>
                                    </div>
                                </div>
                                <div className='accordion-item'>
                                    <h2 className='accordion-header' id='headingTwo'>
                                    <button className='accordion-button collapsed clinic-fs-4 fw-5' type='button' data-bs-toggle='collapse' data-bs-target='#collapseTwo' aria-expanded='false' aria-controls='collapseTwo'>
                                        ما مدى أمان بياناتي ومعلومات عملائي؟
                                    </button>
                                    </h2>
                                    <div id='collapseTwo' className='accordion-collapse collapse' aria-labelledby='headingTwo' data-bs-parent='#accordionFAQ'>
                                    <div className='accordion-body'>
                                        كلينيك انلوك يتخذ أقصى الإجراءات لحماية بيانات العملاء، بدءا من التنفيذ على خادم العميل الخاص، إلى تشفير البيانات، إلى التسلسل الدقيق للوصول من الفريق التقني مع المصادقة من جانب العميل.
                                    </div>
                                    </div>
                                </div>
                                <div className='accordion-item'>
                                    <h2 className='accordion-header' id='headingThree'>
                                    <button className='accordion-button collapsed clinic-fs-4 fw-5' type='button' data-bs-toggle='collapse' data-bs-target='#collapseThree' aria-expanded='false' aria-controls='collapseThree'>
                                        هل يمكنني استخدام كلينيك انلوك مع نظام حجزي الحالي؟
                                    </button>
                                    </h2>
                                    <div id='collapseThree' className='accordion-collapse collapse' aria-labelledby='headingThree' data-bs-parent='#accordionFAQ'>
                                    <div className='accordion-body'>
                                        نعم، كلينيك انلوك يتكامل مع أي نظام حالي من خلال واجهة برمجة التطبيقات (API)، والتي ستتيح لك استخدامه كنظام مكمل مع نظام ERP الداخلي أو نظام الحجز الحالي.
                                    </div>
                                    </div>
                                </div>
                                <div className='accordion-item'>
                                    <h2 className='accordion-header' id='headingFour'>
                                    <button className='accordion-button collapsed clinic-fs-4 fw-5' type='button' data-bs-toggle='collapse' data-bs-target='#collapseFour' aria-expanded='false' aria-controls='collapseThree'>
                                        ما مدى سهولة وسرعة دمج كلينيك انلوك؟
                                    </button>
                                    </h2>
                                    <div id='collapseFour' className='accordion-collapse collapse' aria-labelledby='headingFour' data-bs-parent='#accordionFAQ'>
                                    <div className='accordion-body'>
                                        كلينيك انلوك يوفر جميع الطرق الممكنة للتكامل مع الأنظمة الحالية، من التوثيق والأدوات والمرونة. يمكنك أيضا استخدام مجموعة التقنية الخاصة بك للتكامل أو استخدام مجموعتنا الخاصة لمساعدتك في التكامل.
                                    </div>
                                    </div>
                                </div>
                                <div className='accordion-item'>
                                    <h2 className='accordion-header' id='headingFive'>
                                    <button className='accordion-button collapsed clinic-fs-4 fw-5' type='button' data-bs-toggle='collapse' data-bs-target='#collapseFive' aria-expanded='false' aria-controls='collapseThree'>
                                        كيف نقوم بتسعير 'كلينيك انلوك'
                                    </button>
                                    </h2>
                                    <div id='collapseFive' className='accordion-collapse collapse' aria-labelledby='headingFive' data-bs-parent='#accordionFAQ'>
                                    <div className='accordion-body'>
                                        يتم تحديد الأسعار خصيصًا لكل عميل بناءً على حجم العيادة ونطاق العمل والمميزات وما إلى ذلك. وبمجرد أن نتفق على مخطط المشروع، نصدر الاقتراح في غضون 24 ساعة.
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AccordianSection