import React from 'react';
import SelfBookingImg1 from '../../assets/product-images/selfbooking-img1.webp';
import SelfBookingImg2 from '../../assets/product-images/selfbooking-img2.webp';
import SelfBookingImg3 from '../../assets/product-images/selfbooking-img3.webp';
import SelfBookingToggle1 from '../../assets/product-images/selfbooking-toggle1.webp';
import SelfBookingToggle2 from '../../assets/product-images/selfbooking-toggle2.webp';


const SelfBookingSection = () => {
    return (
        <section className='selfbooking-section'>
            <div className='selfbooking-wrapper'>
                <h2 className='selfbooking-heading'>New <span className='selfbooking-spn green'>self booking</span> channels</h2>
                <p className='selfbooking-text'>Clinic Unlock offers <b>3 channels for booking automations and self bookings</b> that go hand in hand to maximize your <br className='hide-mb' />bookings and revenue while minimizing manual staff work and repetitive tasks</p>
                <div className='selfbooking-inner-wrap'>
                    <div className='self-col-wrap'>
                        <div className='self-col-img-wrap'><img src={SelfBookingImg1} alt='Unified WhatsApp' className='self-col-img self-col-img1' /><img src={SelfBookingToggle1} alt='Toggle Line' className='self-toggle-img1' /></div>
                        <h3 className='self-col-heading'>Unified WhatsApp number</h3>
                        <p className='self-col-text'>Interactive WhatsApp</p>
                    </div>
                    <div className='self-col-wrap'>
                        <div className='self-col-img-wrap'><img src={SelfBookingImg2} alt='Clinic Website' className='self-col-img self-col-img2' /></div>
                        <h3 className='self-col-heading'>Website</h3>
                        <p className='self-col-text'>Clinic Website</p>
                    </div>
                    <div className='self-col-wrap'>
                        <div className='self-col-img-wrap'><img src={SelfBookingImg3} alt='Mobile App' className='self-col-img self-col-img3' /><img src={SelfBookingToggle2} alt='Toggle Line' className='self-toggle-img2' /></div>
                        <h3 className='self-col-heading'>Mobile App</h3>
                        <p className='self-col-text'>complete clinic application within days</p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default SelfBookingSection