import React from 'react'

const deleteEn = () => {
  return (
    <div className="office-wrapper" style={{padding: "4rem 0 0 0"}}>
        <section className='privacy-section'>
            <h1>Surgeca App Data Deletion Request</h1>
            <p>Thank you for choosing Surgeca App. We understand the importance of data privacy and are committed to ensuring a transparent process for managing your information. If you wish to have your account and associated data deleted from our system, please follow the steps outlined below:</p>
            <p>Initiate the Request: To request the deletion of your account and data, please visit the account settings section within the Surgeca App. Look for the option specifically labeled "Delete Account" or "Data Deletion Request".</p>
            <p>Confirmation: Once you've initiated the request, you may be required to confirm your decision. This step is essential to ensure the security of your account and prevent unauthorized deletions.</p>
            <p>Data Deletion Process: After confirming your request, our system will begin the process of permanently deleting your account and associated data. This process may take a short period to complete, depending on the volume of data associated with your account.</p>
                <p>Types of Data Deleted: When you request the deletion of your Surgeca App account, we ensure the removal of all personally identifiable information (PII) associated with your account. This includes but is not limited to your name, email address, contact information, and any user-generated content.</p>
                <p>Retention Period: We commit to promptly deleting your data upon receiving your request. Any residual data that may remain in our backups or archives will be securely erased within [Specify retention period, e.g., 30 days] days of your deletion request.</p>
                <p>At Surgeca App, we value your privacy and are dedicated to providing a seamless experience for managing your data. If you have any questions or encounter any issues while requesting the deletion of your account, please don't hesitate to contact our support team for assistance.</p>
            <p>Thank you for your trust in Surgeca App.</p>
        </section>
    </div>
  )
}

export default deleteEn