import React, {useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import ClinicLogoBlue from '../../assets/product-images/clinic-logo-blue.webp';
import ClinicLogoBlueAr from '../../assets/product-images/clinic-logo-blue-ar.webp';
import Aos from 'aos';
import 'aos/dist/aos.css';

const ProductHeroSection = () => {
  useEffect(() => {
    Aos.init({duration: 2000});
  }, [])
  return (
    <section className='product-hero-section arabic center'>
        <div className='product-hero-wrap'>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-12'>
                        <h1 className='product-hero-heading blue fw-7'><span className='clinic-logo-blue'><img src={ClinicLogoBlueAr} alt='Clinic Unlock' className='product-unlock-heading' /></span><br /> النظام الأمثل لتطوير أداء العيادات</h1>
                            <p className='product-hero-text clinic-fs-5 fw-4 m-0'>كلينيك انلوك <span className='fw-7'>نظام تطوير أداء العيادات</span> يبسط كل مرحلة من مراحل رحلة حجز العميل بشكل مثالي، من <span className='fw-7'>أتمتة <br /> الحجوزات و المواعيد</span> <span className='fw-7'>إلى التذكير و حملات التسويق عبر الواتساب</span>. </p>
                            <p className='product-hero-text clinic-fs-5 fw-4 m-0'>يستخدم أفضل القنوات وأذكى الطرق لتقليل التكاليف وزيادة الإيرادات وتحسين الولاء.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    
  )
}

export default ProductHeroSection