import React from 'react';

const AboutCTASection = () => {
    return (
        <section className='about-cta-section arabic' id='meeting-link-wrap'>
            <div className='about-cta-wrapper'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-5'>
                            <h3 className='about-cta-heading white'>اعرف المزيد عن منتجاتنا <br className='hide-br-mb'/>وخدماتنا اليوم!</h3>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AboutCTASection