import React from 'react';


const CTASection = () => {
    return(
        <>
            <section className='cta-section' id='meeting-link-wrap'>
                <div className='cta-wrapper'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-4'>
                                <h2 className='cta-heading clinic-hs-3 fw-7 white'><span className='fw-4'>Ready to</span> Unlock your <br />clinic’s potential? </h2>
                                <p className='cta-text clinic-fs-4 m-0 white'>Have an assessment call with us.</p>
                                <div className='cta-one-btn-wrap only-mob mt-3'><a href='https://pro.flash-lead.com/meeting-schedule/clinic-unlock' target='_blank' className='btn clinic-fs-5 fw-6 white book-call-btn'>Book a Call</a></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
            
    )
}

export default CTASection