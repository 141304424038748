import React from 'react';
import HomeIcon from '../../assets/product-images/blue-home.webp';
import ClockIcon from '../../assets/product-images/blue-clock.webp';
import DocumentIcon from '../../assets/product-images/blue-document.webp';

const PreventionReasonSection = () => {
    return (
        <section className='prevention-reason-section'>
            <div className='sf-reason-wrapper'>
                <div className='sf-reason-inner-wrap'>
                    <h2 className='sf-reason-heading'>Why <br className='' /><span className='blue'>No show<br className='hide-mb' /></span>prevention ?</h2>
                    <div className='sf-reason-col-wrap'>
                        <div className='prevent-reason-icon-wrap'><img src={HomeIcon} alt='Home Icon' className='sf-reason-icon' /></div>
                        <p className='sf-reason-col-text'><span className='fw-6'>Clinics lose up to 60% of potential slot </span>due to no show</p>
                    </div>

                    <div className='sf-reason-col-wrap'>
                        <div className='prevent-reason-icon-wrap'><img src={ClockIcon} alt='Clock Icon' className='sf-reason-icon' /></div>
                        <p className='sf-reason-col-text'>Updated confirmation can <span className='fw-6'>allow reselling or rescheduling the slot</span></p>
                    </div>

                    <div className='sf-reason-col-wrap'>
                        <div className='prevent-reason-icon-wrap'><img src={DocumentIcon} alt='Document Icon' className='sf-reason-icon' /></div>
                        <p className='sf-reason-col-text'><span className='fw-6'>Create patient profiles</span> and scores based on historical data</p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default PreventionReasonSection