import React from 'react';
import GreenClockIcon from '../../assets/product-images/green-clock.webp';
import GreenWhatsappIcon from '../../assets/product-images/green-whatsapp.webp';
import GreenDollarIcon from '../../assets/product-images/green-dollar.webp';


const SFReasonSection = () => {
    return (
        <section className='sf-reason-section'>
            <div className='sf-reason-wrapper'>
                <div className='sf-reason-inner-wrap'>
                    <h2 className='sf-reason-heading'>Why <br className='' />New <span className='green'>self<br className='hide-mb' /> booking</span><br className='hide-mb' /> channels ?</h2>
                    <div className='sf-reason-col-wrap'>
                        <div className='sf-reason-icon-wrap'><img src={GreenClockIcon} alt='Clock Icon' className='sf-reason-icon' /></div>
                        <p className='sf-reason-col-text'>Enable <span className='fw-6'>60 second booking</span></p>
                    </div>

                    <div className='sf-reason-col-wrap'>
                        <div className='sf-reason-icon-wrap'><img src={GreenWhatsappIcon} alt='Whatsapp Icon' className='sf-reason-icon' /></div>
                        <p className='sf-reason-col-text'>Unlock <span className='fw-6'>New generation preferred booking</span> method</p>
                    </div>

                    <div className='sf-reason-col-wrap'>
                        <div className='sf-reason-icon-wrap'><img src={GreenDollarIcon} alt='Dollar Icon' className='sf-reason-icon' /></div>
                        <p className='sf-reason-col-text'>Decrease <span className='fw-6'>cost of booking agents</span></p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default SFReasonSection