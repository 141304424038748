import React from 'react';
import HomeIcon from '../../assets/product-images/blue-home.webp';
import ClockIcon from '../../assets/product-images/blue-clock.webp';
import DocumentIcon from '../../assets/product-images/blue-document.webp';

const PreventionReasonSection = () => {
    return (
        <section className='prevention-reason-section arabic'>
            <div className='sf-reason-wrapper'>
                <div className='sf-reason-inner-wrap'>
                    <h2 className='sf-reason-heading'>لماذا <br className=''/><span className='blue fw-7'>نظام الحـد من <br className='hide-mb'/>تغيب المرضي ?</span></h2>
                    <div className='sf-reason-col-wrap'>
                        <div className='prevent-reason-icon-wrap'><img src={HomeIcon} alt='Home Icon' className='sf-reason-icon' /></div>
                        <p className='sf-reason-col-text'><b>تفقد العيادة ما يصــــل إلى<br className='hide-mb'/> 60% من المساحة المحتملة</b> <br className='hide-mb'/>بسبب عدم الـــــحضور</p>
                    </div>

                    <div className='sf-reason-col-wrap'>
                        <div className='prevent-reason-icon-wrap'><img src={ClockIcon} alt='Clock Icon' className='sf-reason-icon' /></div>
                        <p className='sf-reason-col-text'>يمكن أن يســــــــــمح<br className='hide-mb'/> التأكيد المحــــــــــدث <br className='hide-mb'/><b>بإعادة بيع المواعيد</b></p>
                    </div>

                    <div className='sf-reason-col-wrap'>
                        <div className='prevent-reason-icon-wrap'><img src={DocumentIcon} alt='Document Icon' className='sf-reason-icon' /></div>
                        <p className='sf-reason-col-text'><b>إنشاء ملفات تعــريف<br className='hide-mb'/> المرضى</b> ونتائجهم بناءً<br className='hide-mb'/> على البيانات التاريخية</p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default PreventionReasonSection