import React from 'react'
import './Footer.css'
import 'bootstrap/dist/css/bootstrap.css';
import ClinicLogo from '../../assets/cliniclogo.svg';

const Footer = () => {
  return (
    <footer className='footer-section'>
        <div className='footer-wrapper'>
            <div className='footer-logo'><a className='' href='/en/'><img src={ClinicLogo} alt='Clinic Unlock' /></a></div>
            <div className='footer-link-wrap'>
                <a href='/en/about' className='footer-link'>About</a>
                <a href='/en/privacy' className='footer-link'>Privacy Policy</a>
            </div>
            <div className='footer-text clinic-fs-3 fw-4'>Powered by <span className='flashlead fw-6'>Flash Lead</span></div>
        </div>
    </footer>
  )
}

export default Footer