import React from 'react';
import CheckIcon from '../../assets/product-images/green-check.webp';


const PricingSection = () => {
    return (
        <section className='product-pricing-section'>
            <div className='product-pricing-wrapper'>
                <h3 className='pricing-heading'>Pricing</h3>
                <div className='pricing-card-wrapper'>
                    <div className='pricing-card'>
                        <div className='pricing-card-txt-wrap'>
                            <div className='pricing-card-txt'>New self booking channels</div>
                            <div className='pricing-card-heading'>Agent & Website Booking</div>
                        </div>
                        <div className='pricing-card-start'>Starting from</div>
                        <div className='pricing-card-price mb-4'>3500 SAR <span className='pricing-card-price-spn'>/Year</span></div>
                        <div className='pricing-card-benefit-wrap'>
                            <p className='pricing-benefit-txt'>Benefits</p>
                            <ul className='pricing-benefit-ul'>
                                <li className='pricing-benefit-li'><span className='pricing-ul-spn'><img src={CheckIcon} alt='Green Check Icon' className='pricing-li-icon' /></span> Embedded Web Booking page</li>
                                <li className='pricing-benefit-li'><span className='pricing-ul-spn'><img src={CheckIcon} alt='Green Check Icon' className='pricing-li-icon' /></span> Agent Dashboard for call center booking</li>
                                <li className='pricing-benefit-li'><span className='pricing-ul-spn'><img src={CheckIcon} alt='Green Check Icon' className='pricing-li-icon' /></span> 1 Branch</li>
                                <li className='pricing-benefit-li'><span className='pricing-ul-spn'><img src={CheckIcon} alt='Green Check Icon' className='pricing-li-icon' /></span> Up to 10 Agent</li>
                                <li className='pricing-benefit-li'><span className='pricing-ul-spn'><img src={CheckIcon} alt='Green Check Icon' className='pricing-li-icon' /></span> Up to 10 Doctor</li>
                                <li className='pricing-benefit-li'><span className='pricing-ul-spn'><img src={CheckIcon} alt='Green Check Icon' className='pricing-li-icon' /></span> Extra Doctor 150-200 SAR</li>
                                <li className='pricing-benefit-li'><span className='pricing-ul-spn'><img src={CheckIcon} alt='Green Check Icon' className='pricing-li-icon' /></span> Extra Agent 50 SAR</li>
                                <li className='pricing-benefit-li'><span className='pricing-ul-spn'><img src={CheckIcon} alt='Green Check Icon' className='pricing-li-icon' /></span> Whatsapp Confirmation included (Up to 1000 message Monthly)</li>
                            </ul>
                        </div>
                        <div className='pricing-card-btn-wrap'><a href='https://pro.flash-lead.com/meeting-schedule/clinic-unlock' className='btn product-pricing-btn'>Start Now</a></div>

                    </div>
                    <div className='pricing-card pricing-second-card'>
                        <div className='pricing-card-txt-wrap'>
                            <div className='pricing-card-txt'>New self booking channels</div>
                            <div className='pricing-card-heading'>Agent/Website/<br />App/Whatsapp</div>
                        </div>
                        <div className='pricing-card-start'>Starting from</div>
                        <div className='pricing-card-price mb-4'>5500 SAR <span className='pricing-card-price-spn'>/Year</span></div>
                        <div className='pricing-card-benefit-wrap'>
                            <p className='pricing-benefit-txt'>Benefits</p>
                            <ul className='pricing-benefit-ul'>
                                <li className='pricing-benefit-li'><span className='pricing-ul-spn'><img src={CheckIcon} alt='Green Check Icon' className='pricing-li-icon' /></span> White label Mobile App</li>
                                <li className='pricing-benefit-li'><span className='pricing-ul-spn'><img src={CheckIcon} alt='Green Check Icon' className='pricing-li-icon' /></span> Programmatic Whatsapp</li>
                                <li className='pricing-benefit-li'><span className='pricing-ul-spn'><img src={CheckIcon} alt='Green Check Icon' className='pricing-li-icon' /></span> Embedded Web Booking page</li>
                                <li className='pricing-benefit-li'><span className='pricing-ul-spn'><img src={CheckIcon} alt='Green Check Icon' className='pricing-li-icon' /></span> Agent Dashboard for call center booking</li>
                                <li className='pricing-benefit-li'><span className='pricing-ul-spn'><img src={CheckIcon} alt='Green Check Icon' className='pricing-li-icon' /></span> 1 Branch</li>
                                <li className='pricing-benefit-li'><span className='pricing-ul-spn'><img src={CheckIcon} alt='Green Check Icon' className='pricing-li-icon' /></span> Up to 10 Agent</li>
                                <li className='pricing-benefit-li'><span className='pricing-ul-spn'><img src={CheckIcon} alt='Green Check Icon' className='pricing-li-icon' /></span> Up to 10 Doctor</li>
                                <li className='pricing-benefit-li'><span className='pricing-ul-spn'><img src={CheckIcon} alt='Green Check Icon' className='pricing-li-icon' /></span> Extra Doctor 150 SAR</li>
                                <li className='pricing-benefit-li'><span className='pricing-ul-spn'><img src={CheckIcon} alt='Green Check Icon' className='pricing-li-icon' /></span> Extra Agent 50 SAR</li>
                                <li className='pricing-benefit-li'><span className='pricing-ul-spn'><img src={CheckIcon} alt='Green Check Icon' className='pricing-li-icon' /></span> Whatsapp Confirmation included (Up to 1000 message Monthly)</li>
                            </ul>
                        </div>
                        <div className='pricing-card-btn-wrap'><a href='https://pro.flash-lead.com/meeting-schedule/clinic-unlock' className='btn product-pricing-btn'>Start Now</a></div>

                    </div>
                    <div className='pricing-card'>
                        <div className='pricing-card-txt-wrap'>
                            <div className='pricing-card-txt'>No show prevention</div>
                            <div className='pricing-card-heading'>No show prevention</div>
                        </div>
                        <div className='pricing-card-start'>Starting from</div>
                        <div className='pricing-card-price mb-4'>3500 SAR <span className='pricing-card-price-spn'>/Year</span></div>
                        <div className='pricing-card-benefit-wrap'>
                            <p className='pricing-benefit-txt'>Benefits</p>
                            <ul className='pricing-benefit-ul'>
                                <li className='pricing-benefit-li'><span className='pricing-ul-spn'><img src={CheckIcon} alt='Green Check Icon' className='pricing-li-icon' /></span> Flow builder</li>
                                <li className='pricing-benefit-li'><span className='pricing-ul-spn'><img src={CheckIcon} alt='Green Check Icon' className='pricing-li-icon' /></span> Whatsapp API confirmation</li>
                                <li className='pricing-benefit-li'><span className='pricing-ul-spn'><img src={CheckIcon} alt='Green Check Icon' className='pricing-li-icon' /></span> Interactive ChatBot</li>
                                <li className='pricing-benefit-li'><span className='pricing-ul-spn'><img src={CheckIcon} alt='Green Check Icon' className='pricing-li-icon' /></span> Up to 800 Appointment month</li>
                                <li className='pricing-benefit-li'><span className='pricing-ul-spn'><img src={CheckIcon} alt='Green Check Icon' className='pricing-li-icon' /></span> Each 200 extra appointments 200 SAR</li>
                                <li className='pricing-benefit-li'><span className='pricing-ul-spn'><img src={CheckIcon} alt='Green Check Icon' className='pricing-li-icon' /></span> Automated confirmation</li>
                                <li className='pricing-benefit-li'><span className='pricing-ul-spn'><img src={CheckIcon} alt='Green Check Icon' className='pricing-li-icon' /></span> Open API</li>
                                <li className='pricing-benefit-li'><span className='pricing-ul-spn'><img src={CheckIcon} alt='Green Check Icon' className='pricing-li-icon' /></span> Up to 10 Doctor</li>
                            </ul>
                        </div>
                        <div className='pricing-card-btn-wrap'><a href='https://pro.flash-lead.com/meeting-schedule/clinic-unlock' className='btn product-pricing-btn'>Start Now</a></div>

                    </div>
                </div>
            </div>
        </section>
    )
}

export default PricingSection