import React from 'react';
import BenefitIcon1 from '../../assets/product-images/blue-benefit-icon1.webp'
import BenefitIcon2 from '../../assets/product-images/blue-benefit-icon2.webp'
import BenefitIcon3 from '../../assets/product-images/blue-benefit-icon3.webp'
import BenefitIcon4 from '../../assets/product-images/blue-benefit-icon4.webp'
import BenefitIcon5 from '../../assets/product-images/blue-benefit-icon5.webp'


const BlueBenefitSection = () => {
    return (
        <section className='book-benefit-section arabic'>
            <div className='book-benefit-wrapper'>
                <p className='book-benefit-text'>نظام <span className='blue'>الحد من تغيب المرضي</span></p>
                <h3 className='book-benefit-heading' style={{letterSpacing: '0'}}>الفوائد الرئيسية</h3>
                <div className='book-benefit-card-wrapper'>
                    <div className='book-benefit-card'>
                        <div className='book-benefit-card-icon-wrap'><img src={BenefitIcon1} alt='Patient Report Icon' className='book-benefit-card-icon' /></div>
                        <div className='book-benefit-card-txt'><b>توقع سلوك المريض</b><br/> الخاص بك</div>
                    </div>
                    <div className='book-benefit-card'>
                        <div className='book-benefit-card-icon-wrap'><img src={BenefitIcon2} alt='Missing Slot Icon' className='book-benefit-card-icon' /></div>
                        <div className='book-benefit-card-txt'><b>تقليل المواعيد <br/>المفقوده</b></div>
                    </div>
                    <div className='book-benefit-card'>
                        <div className='book-benefit-card-icon-wrap'><img src={BenefitIcon3} alt='Growth Icon' className='book-benefit-card-icon' /></div>
                        <div className='book-benefit-card-txt'>قم <b>بتنمية قنوات <br/>مبيعاتك</b></div>
                    </div>
                    <div className='book-benefit-card'>
                        <div className='book-benefit-card-icon-wrap'><img src={BenefitIcon4} alt='API Icon' className='book-benefit-card-icon' /></div>
                        <div className='book-benefit-card-txt'>إمكانية <b>الربط مع نظام العيادة <br/>المستخدم</b> عن طريق الـ API</div>
                    </div>
                    <div className='book-benefit-card'>
                        <div className='book-benefit-card-icon-wrap'><img src={BenefitIcon5} alt='Report Icon' className='book-benefit-card-icon' /></div>
                        <div className='book-benefit-card-txt'><b>لوحة التقارير</b> تساعدك <br/>في التحليل وأخذ القرارات</div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default BlueBenefitSection