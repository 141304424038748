import React from 'react';
import GreenClockIcon from '../../assets/product-images/green-clock.webp';
import GreenWhatsappIcon from '../../assets/product-images/green-whatsapp.webp';
import GreenDollarIcon from '../../assets/product-images/green-dollar.webp';


const SFReasonSection = () => {
    return (
        <section className='sf-reason-section arabic'>
            <div className='sf-reason-wrapper'>
                <div className='sf-reason-inner-wrap'>
                    <h2 className='sf-reason-heading'>لماذا <br className='' /><span className='green'>قنوات حــجز<br className='hide-mb' /> ذاتي جديدة ?</span></h2>
                    <div className='sf-reason-col-wrap'>
                        <div className='sf-reason-icon-wrap'><img src={GreenClockIcon} alt='Clock Icon' className='sf-reason-icon' /></div>
                        <p className='sf-reason-col-text'>تمكين <b>الـحجز <br className='hide-mb' /> في 60 ثانـية</b></p>
                    </div>

                    <div className='sf-reason-col-wrap'>
                        <div className='sf-reason-icon-wrap'><img src={GreenWhatsappIcon} alt='Whatsapp Icon' className='sf-reason-icon' /></div>
                        <p className='sf-reason-col-text'>فتح <b>طريقة الحجز المفضلة <br className='hide-mb' />للجيل الجديد</b> من الـــــشباب</p>
                    </div>

                    <div className='sf-reason-col-wrap'>
                        <div className='sf-reason-icon-wrap'><img src={GreenDollarIcon} alt='Dollar Icon' className='sf-reason-icon' /></div>
                        <p className='sf-reason-col-text'><b>تقليل تكلفة</b> و عدد <br className='hide-mb' />موظفي الحـــــــــجز</p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default SFReasonSection