import React from 'react'
import './Header.css'
import 'bootstrap/dist/css/bootstrap.css';
import clinicLogo from '../../assets/Clinic-Logo.svg';
import {Routes, useLocation, useSearchParams } from "react-router-dom";
import {Helmet} from "react-helmet";

const Header = () => {
    const location = useLocation();
    const navigateArabic = function (e){
        e.preventDefault();
        window.location.href = location.pathname.replace('/en/','/');
    }

    const [searchParams, setSearchParams] = useSearchParams();
    let email = searchParams.get("email");
    let scriptcode = "";
    if(email === null || email === "") {
        scriptcode = "snaptr('init', '6b4819a8-6882-4755-9631-a1ea683366f4', {}); snaptr('track', 'PAGE_VIEW');";
    }else{
        scriptcode = "snaptr('init', '6b4819a8-6882-4755-9631-a1ea683366f4', { 'user_email': '"+email+"' });\n" +
            "        snaptr('track', 'SIGN_UP');";
    }
  return (
    <header className='clinic-header'>
        <Helmet>
            <script>{scriptcode}</script>
        </Helmet>
      <nav className='navbar navbar-expand-lg'>
          <div className='container-fluid bnner-nav-container'>
              <a className='navbar-brand' href='/en/'><img src={clinicLogo} alt='Icon' className='clinics-logo' /></a>
              <button className='navbar-toggler' type='button' data-bs-toggle='collapse' data-bs-target='#navbarSupportedContent' aria-controls='navbarSupportedContent' aria-expanded='false' aria-label='Toggle navigation'>
                  <span className='navbar-toggler-icon'></span>
              </button>
              <div className='collapse navbar-collapse' id='navbarSupportedContent'>
                  <ul className='navbar-nav mb-2 mb-lg-0 clinics-nav-ul'>
                      <li className='nav-item'>
                          <a className={'nav-link '+(location.pathname == "/en/"?'active':' ')} aria-current='page' href='/en/'>Home</a>
                      </li>
                      <li className='nav-item'>
                          <a className={'nav-link '+(location.pathname == "/en/product"?'active':' ')} href='/en/product'>Product</a>
                      </li>
                      <li className='nav-item'>
                          <a className={'nav-link '+(location.pathname == "/en/about"?'active':' ')} href='/en/about'>About</a>
                      </li>
                    <li className='nav-item'>
                          <a className={'nav-link '+(location.pathname == "/en/contact-us"?'active':' ')} href='/en/contact-us'>Contact us</a>
                      </li>
                      <li className='nav-item'>
                          <a className='nav-link arabic-lang' href='#' onClick={navigateArabic}>عربي</a>
                      </li>
                  </ul>
                  <div className='d-flex nav-book-call-wrap' role='button'>
                      <a href='https://pro.flash-lead.com/meeting-schedule/clinic-unlock'  target='_blank' className='btn book-call-btn'>Book a Call</a>
                  </div>
              </div>
          </div>
      </nav>
    </header>
  )
}

export default Header