import React from 'react';
import TimelineMbImg from '../../assets/about-images/about-timeline-mb.webp';
import TimelineMbImgAr from '../../assets/about-images/about-timeline-ar-mb.webp';

const TimelineSection = () => {
    return (
        <section className='about-timeline arabic'>
            <div className='about-timeline-wrapper'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-6'>
                            <h2 className='about-time-heading fw-6 clinic-hs-4'>ساعدنا <span className='blue'>مئــات</span> الصغيرة والمتوسطة</h2>
                            <p className='about-time-heading clinic-fs-5 fw-4'>أطلقنا في أوائل عام 2021، مع التركيز على الشركات الصغيرة والمتوسطة، منتجات المبيعات ودعم العملاء التي ساعدت مئات الشركات الصغيرة والمتوسطة على تحسين أداء مبيعاتها وخدمة العملاء.</p>
                        </div>
                        <div className='col-lg-4'><div className='about-timeline-img-wrap only-mob'><img src={TimelineMbImgAr} alt='Timeline' className='about-timeline-mb' /></div></div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default TimelineSection