import React from 'react';
import AboutMapImg from '../../assets/about-images/map-about.webp';

const OfficeSection = () => {
    return (
        <section className='office-section arabic'>
            <div className='office-wrapper'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-6'>
                            <div className='office-col-wrap'>
                                <h2 className='office-heading clinic-hs-2 fw-6'>مقراتنا</h2>
                                <ul className='office-list'>
                                    <li className='office-li'>في عام 2021 مقرنا الرئيسي في القاهرة، مصر.</li>
                                    <li className='office-li'>و في عام 2021، افتتاح فرعنا في ولاية ديلاوير بالولايات المتحدة الأمريكية.</li>
                                    <li className='office-li'>و في عام 2021،  افتتاح فرعنا في إسلام اباد ، باكستان</li>
                                    <li className='office-li'>و في عام 2022،  افتتاح فرعنا في الرياض، المملكة العربية السعودية.</li>
                                </ul>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className='office-map-wrap'><img src={AboutMapImg} alt='Office Map Image' className='office-map' /></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default OfficeSection