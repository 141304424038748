import React from 'react';


const BusinessSection = () => {
    return (
        <section className='business-section'>
            <div className='business-wrapper'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-6'>
                            <div className='business-wrap'>
                                <h2 className='business-heading clinic-hs-6 fw-6'>Now, we're expanding our focus to <br className='hide-br-mb' /><span className='clinic-hs-4'>include</span> <span className='blue clinic-hs-4'>larger businesses</span></h2>
                                <p className='business-txt clinic-fs-5 fw-4'>We're excited to launch our new brand Clinic Unlock, <br className='hide-br-mb' />powered by Flash Lead.</p>
                                <p className='business-txt clinic-fs-5 fw-4'>Clinic Unlock is a suite of software solutions designed to help <br className='hide-br-mb' />large private clinics operate more efficiently and effectively. Our <br className='hide-br-mb' />product provides clinics with a comprehensive solution for <br className='hide-br-mb' />managing appointments, patients, and communication.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default BusinessSection