import React from 'react';
import ToggleLineL from '../../assets/home-images/toggle-line-l.svg';
import ToggleLineR from '../../assets/home-images/toggle-line-r.svg';
import JourneyBanner from '../../assets/home-images/Journey.svg';
import JourneyBannerAr from '../../assets/home-images/Journey-ar.svg';


const JourneySection = () => {
    return(
        <>
            <section className='journey-section hide-mb arabic'>
                <div className='journey-wrapper'>
                    <div className='journey-heading-wrap'>
                        <img src={ToggleLineR} alt='Toggle' />
                        <h2 className='clinic-hs-4 m-0 fw-400'>غطي كل جزء من الرحلة للعيادة</h2>
                        <img src={ToggleLineL} alt='Toggle' />
                    </div>
                    <img src={JourneyBannerAr} alt='Our Journey' className='d-block w-100' />
                </div>
            </section>
        </>
    )
}

export default JourneySection