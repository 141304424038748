import React from 'react'
import CalenderIcon from '../../assets/home-images/calender-icon.svg'
import ClinicLogo from '../../assets/home-images/cliniclogo.svg'
import ClinicLogoAr from '../../assets/home-images/clinic-logo-ar.svg'
import DesignIcon from '../../assets/home-images/design-icon.svg'
import PerformanceIcon from '../../assets/home-images/performance-icon.svg'



const BenefitSection = () => {
    return (
        <>
            <section className='benefit-section arabic'>
            <div className='benefit-wrapper'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-5'>
                            <div className='benefit-col-wrap'>
                                <div className='benefit-icon-wrap'><img src={CalenderIcon} alt='' className='benefit-icon' /></div>
                                <div className='benefit-txt-wrap'>
                                    <h2 className='benefit-heading'><img src={ClinicLogoAr} alt='Clinic Unlock' /></h2>
                                    <p className='benefit-txt'>النظام الأمثل <span className='fw-7'>لتطوير أداء العيادات</span></p>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-7'>
                            <div className='benefit-col-wrap'>
                                <div className='benefit-icon-wrap'><img src={DesignIcon} alt='Clinic Unlock' className='benefit-icon' /></div>
                                <div className='benefit-txt-wrap'>
                                    <h2 className='benefit-heading clinic-fs-1 fw-7'>مصمم للعيادات المتطورة</h2>
                                    <p className='benefit-txt'>نظام مرن، ويمكنه <span className='fw-7'>الاندماج بسلاسة مع نظامك الحالي</span> وتحسين عملية الحجز الحالية لديك.</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-12'>
                            <div className='benefit-col-wrap m-0'>
                                <div className='benefit-icon-wrap'><img src={PerformanceIcon} alt='' className='benefit-icon' /></div>
                                <div className='benefit-txt-wrap'>
                                    <h2 className='benefit-heading '><img src={ClinicLogoAr} alt='' /></h2>
                                    <p className='benefit-txt'>نظام <span className='fw-7'>تطوير أداء العيادات</span> يبسط كل مرحلة من مراحل رحلة حجز العميل بشكل مثالي، من <span className='fw-7'>أتمتة الحجوزات و المواعيد</span> إلى التذكير <span className='fw-7'>و حملات التسويق عبر الواتساب</span>. يستخدم أفضل القنوات وأذكى الطرق لتقليل التكاليف وزيادة الإيرادات وتحسين الولاء.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </section>
        
        </>
    )
}

export default BenefitSection
