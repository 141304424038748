import React from 'react';
import ToggleLineL from '../../assets/home-images/toggle-line-l.svg'
import ToggleLineR from '../../assets/home-images/toggle-line-r.svg'
import CompaniesLogos from '../../assets/home-images/Logos.svg'


const CompaniesSection = () =>{
    return(
        <>
            <section className='companies-section'>
                <div className='companies-wrapper'>
                    <div className='companies-heading-wrap'>
                        <img src={ToggleLineL} alt='Toggle' />
                        <h2 className='clinic-hs-4 m-0 fw-4'>Trusted and supported by</h2>
                        <img src={ToggleLineR} alt='Toggle' />
                    </div>
                    <div className='companies-img-wrap'><img src={CompaniesLogos} alt='Our Clients' className='companies-logo-img' /></div>
                </div>
            </section>
        </>
    )
}

export default CompaniesSection