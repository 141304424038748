import React from 'react';
import Alaa from '../../assets/about-images/Alaa.webp';
import Jackson from '../../assets/about-images/jackson-harry.webp';
import Caroline from '../../assets/about-images/Caroline.webp';
import Irfan from '../../assets/about-images/Irfan.webp';
import Salaser from '../../assets/about-images/Salaser.webp';
import Rabie from '../../assets/about-images/Mohamed-Rabie.webp';
import Fishawy from '../../assets/about-images/Fishawy.webp';
import Mahmoud from '../../assets/about-images/Mahmoud.webp';
import Maison from '../../assets/about-images/Maison1.webp';
import Abduallah from '../../assets/about-images/Abduallah.webp';
import Zahid from '../../assets/about-images/Zahid.webp';
import Sobal from '../../assets/about-images/Sobal1.webp';
import Ahmed from '../../assets/about-images/ahmed.webp';
import Islam from '../../assets/about-images/islam.webp';
import LinkedInIcon from '../../assets/about-images/linkedin-icon-black.webp';
import TwitterIcon from '../../assets/about-images/x-icon-black.webp';


const TeamSection = () => {
    return (
        <section className='team-section arabic'>
            <div className='team-main-wrapper'>
                <div className='container'>
                    <div className='row team-row'>
                        {/* <!-- col start --> */}
                        <div className='col-lg-12'>
                            <div className='team-heading-wrap'>
                                <h2 className='team-heading'><span className='team-head-spn blue'>فريق</span> كلينك انلوك</h2>
                                <p className='team-head-text'>+ 4 درجات ماجستير في الذكاء الاصطناعي وعلوم البيانات</p>
                            </div>
                        </div>
                        {/* <!-- col end --> */}
                        {/* <!-- col start --> */}
                        <div className='col-lg-3 col-sm-4'>
                            <div className='team-img-wrap'>
                                <img src={Alaa} alt='Alaa Mourad' className='team-img' />
                                <div className='team-main-hover-layer'>
                                    <div className='team-sub-hover-layer'>
                                        <div className='Team-social-wrap'>
                                            <a href='https://www.linkedin.com/in/alaa-mourad-79106635/' className='team-social-link' target='_blank'><img src={LinkedInIcon} alt='LinkedIn Icon' className='team-social-icon' /></a>
                                            <a href='https://twitter.com/ClinicUnlock' className='team-social-link' target='_blank'><img src={TwitterIcon} alt='Twitter Icon' className='team-social-icon' /></a>
                                        </div>
                                        <div className='team-info-wrap'>
                                            <h3 className='team-mem-name'>علاء مراد</h3>
                                            <p className='team-mem-position'>مدير و رئيس تنفيذي</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='team-info-wrap-bot'>
                                <h3 className='team-mem-name-bot'>علاء مراد</h3>
                                <p className='team-mem-position-bot'>مدير و رئيس تنفيذي</p>
                                <div className='team-socialIcon-wrap'>
                                    <a href='https://www.linkedin.com/in/alaa-mourad-79106635/' className='team-social-link' target='_blank'><img src={LinkedInIcon} alt='LinkedIn Icon' className='team-social-icon' /></a>
                                    <a href='https://twitter.com/ClinicUnlock' className='team-social-link' target='_blank'><img src={TwitterIcon} alt='Twitter Icon' className='team-social-icon' /></a>
                                </div>
                            </div>
                        </div>
                        {/* <!-- col end --> */}
                        {/* <!-- col start --> */}
                        <div className='col-lg-3 col-sm-4'>
                            <div className='team-img-wrap'>
                                <img src={Jackson} alt='Jackson Harry' className='team-img' />
                                <div className='team-main-hover-layer'>
                                    <div className='team-sub-hover-layer'>
                                        <div className='Team-social-wrap'>
                                            <a href='https://www.linkedin.com/in/jackson-harry/' className='team-social-link' target='_blank'><img src={LinkedInIcon} alt='LinkedIn Icon' className='team-social-icon' /></a>
                                            <a href='https://twitter.com/ClinicUnlock' className='team-social-link' target='_blank'><img src={TwitterIcon} alt='Twitter Icon' className='team-social-icon' /></a>
                                        </div>
                                        <div className='team-info-wrap'>
                                            <h3 className='team-mem-name'>جاكسون هاري</h3>
                                            <p className='team-mem-position'>مدير مشارك و مدير التكنولوجيا</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='team-info-wrap-bot'>
                                <h3 className='team-mem-name-bot'>جاكسون هاري</h3>
                                <p className='team-mem-position-bot'>مدير مشارك و مدير التكنولوجيا</p>
                                <div className='team-socialIcon-wrap'>
                                    <a href='https://www.linkedin.com/in/jackson-harry/' className='team-social-link' target='_blank'><img src={LinkedInIcon} alt='LinkedIn Icon' className='team-social-icon' /></a>
                                    <a href='https://twitter.com/ClinicUnlock' className='team-social-link' target='_blank'><img src={TwitterIcon} alt='Twitter Icon' className='team-social-icon' /></a>
                                </div>
                            </div>
                        </div>
                        {/* <!-- col end --> */}
                        {/* <!-- col start --> */}
                        <div className='col-lg-3 col-sm-4'>
                            <div className='team-img-wrap'>
                                <img src={Caroline} alt='Caroline' className='team-img' />
                                <div className='team-main-hover-layer'>
                                    <div className='team-sub-hover-layer'>
                                        <div className='Team-social-wrap'>
                                            <a href='https://www.linkedin.com/in/caroline-robert1/' className='team-social-link' target='_blank'><img src={LinkedInIcon} alt='LinkedIn Icon' className='team-social-icon' /></a>
                                            <a href='https://twitter.com/ClinicUnlock' className='team-social-link' target='_blank'><img src={TwitterIcon} alt='Twitter Icon' className='team-social-icon' /></a>
                                        </div>
                                        <div className='team-info-wrap'>
                                            <h3 className='team-mem-name'>كارولين روبير</h3>
                                            <p className='team-mem-position'>مديرة التسويق</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='team-info-wrap-bot'>
                                <h3 className='team-mem-name-bot'>كارولين روبير</h3>
                                <p className='team-mem-position-bot'>مديرة التسويق</p>
                                <div className='team-socialIcon-wrap'>
                                    <a href='https://www.linkedin.com/in/caroline-robert1/' className='team-social-link' target='_blank'><img src={LinkedInIcon} alt='LinkedIn Icon' className='team-social-icon' /></a>
                                    <a href='https://twitter.com/ClinicUnlock' className='team-social-link' target='_blank'><img src={TwitterIcon} alt='Twitter Icon' className='team-social-icon' /></a>
                                </div>
                            </div>
                        </div>
                        {/* <!-- col end --> */}
                        {/* <!-- col start --> */}
                        <div className='col-lg-3 col-sm-4'>
                            <div className='team-img-wrap'>
                                <img src={Irfan} alt='Irfan' className='team-img' />
                                <div className='team-main-hover-layer'>
                                    <div className='team-sub-hover-layer'>
                                        <div className='Team-social-wrap'>
                                            <a href='https://www.linkedin.com/in/irfan-bari/' className='team-social-link' target='_blank'><img src={LinkedInIcon} alt='LinkedIn Icon' className='team-social-icon' /></a>
                                            <a href='https://twitter.com/ClinicUnlock' className='team-social-link' target='_blank'><img src={TwitterIcon} alt='Twitter Icon' className='team-social-icon' /></a>
                                        </div>
                                        <div className='team-info-wrap'>
                                            <h3 className='team-mem-name'>عرفان باري</h3>
                                            <p className='team-mem-position'>مدير تكنولوجيا</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='team-info-wrap-bot'>
                                <h3 className='team-mem-name-bot'>عرفان باري</h3>
                                <p className='team-mem-position-bot'>مدير تكنولوجيا</p>
                                <div className='team-socialIcon-wrap'>
                                    <a href='https://www.linkedin.com/in/irfan-bari/' className='team-social-link' target='_blank'><img src={LinkedInIcon} alt='LinkedIn Icon' className='team-social-icon' /></a>
                                    <a href='https://twitter.com/ClinicUnlock' className='team-social-link' target='_blank'><img src={TwitterIcon} alt='Twitter Icon' className='team-social-icon' /></a>
                                </div>
                            </div>
                        </div>
                        {/* <!-- col end --> */}
                        {/* <!-- col start --> */}
                        <div className='col-lg-3 col-sm-4'>
                            <div className='team-img-wrap'>
                                <img src={Salaser} alt='Salaser' className='team-img' />
                                <div className='team-main-hover-layer'>
                                    <div className='team-sub-hover-layer'>
                                        <div className='Team-social-wrap'>
                                            <a href='https://www.linkedin.com/in/slaser/' className='team-social-link' target='_blank'><img src={LinkedInIcon} alt='LinkedIn Icon' className='team-social-icon' /></a>
                                            <a href='https://twitter.com/ClinicUnlock' className='team-social-link' target='_blank'><img src={TwitterIcon} alt='Twitter Icon' className='team-social-icon' /></a>
                                        </div>
                                        <div className='team-info-wrap'>
                                            <h3 className='team-mem-name'>سلاسر بابو</h3>
                                            <p className='team-mem-position'>مدير مشروع</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='team-info-wrap-bot'>
                                <h3 className='team-mem-name-bot'>سلاسر بابو</h3>
                                <p className='team-mem-position-bot'>مدير مشروع</p>
                                <div className='team-socialIcon-wrap'>
                                    <a href='https://www.linkedin.com/in/slaser/' className='team-social-link' target='_blank'><img src={LinkedInIcon} alt='LinkedIn Icon' className='team-social-icon' /></a>
                                    <a href='https://twitter.com/ClinicUnlock' className='team-social-link' target='_blank'><img src={TwitterIcon} alt='Twitter Icon' className='team-social-icon' /></a>
                                </div>
                            </div>
                        </div>
                        {/* <!-- col end --> */}
                        {/* <!-- col start --> */}
                        <div className='col-lg-3 col-sm-4'>
                            <div className='team-img-wrap'>
                                <img src={Rabie} alt='Rabie' className='team-img' />
                                <div className='team-main-hover-layer'>
                                    <div className='team-sub-hover-layer'>
                                        <div className='team-info-wrap'>
                                            <h3 className='team-mem-name'>محمد ربيع</h3>
                                            <p className='team-mem-position'>قائد فريق مبيعات</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='team-info-wrap-bot'>
                                <h3 className='team-mem-name-bot'>محمد ربيع</h3>
                                <p className='team-mem-position-bot'>قائد فريق مبيعات</p>
                                <div className='team-socialIcon-wrap'>
                                    <a href='https://www.linkedin.com/showcase/clinic-unlock/' className='team-social-link' target='_blank'><img src={LinkedInIcon} alt='LinkedIn Icon' className='team-social-icon' /></a>
                                    <a href='https://twitter.com/ClinicUnlock' className='team-social-link' target='_blank'><img src={TwitterIcon} alt='Twitter Icon' className='team-social-icon' /></a>
                                </div>
                            </div>
                        </div>
                        {/* <!-- col end --> */}
                        {/* <!-- col start --> */}
                        <div className='col-lg-3 col-sm-4'>
                            <div className='team-img-wrap'>
                                <img src={Fishawy} alt='Fishawy' className='team-img' />
                                <div className='team-main-hover-layer'>
                                    <div className='team-sub-hover-layer'>
                                        <div className='team-info-wrap'>
                                            <h3 className='team-mem-name'>محمد الفيشاوي</h3>
                                            <p className='team-mem-position'>مصمم منتجات/ مصمم UIUX</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='team-info-wrap-bot'>
                                <h3 className='team-mem-name-bot'>محمد الفيشاوي</h3>
                                <p className='team-mem-position-bot'>مصمم منتجات/ مصمم UIUX</p>
                                <div className='team-socialIcon-wrap'>
                                    <a href='https://www.linkedin.com/showcase/clinic-unlock/' className='team-social-link' target='_blank'><img src={LinkedInIcon} alt='LinkedIn Icon' className='team-social-icon' /></a>
                                    <a href='https://twitter.com/ClinicUnlock' className='team-social-link' target='_blank'><img src={TwitterIcon} alt='Twitter Icon' className='team-social-icon' /></a>
                                </div>
                            </div>
                        </div>
                        {/* <!-- col end --> */}
                        {/* <!-- col start --> */}
                        <div className='col-lg-3 col-sm-4'>
                            <div className='team-img-wrap'>
                                <img src={Mahmoud} alt='Mahmoud' className='team-img' />
                                <div className='team-main-hover-layer'>
                                    <div className='team-sub-hover-layer'>
                                        <div className='team-info-wrap'>
                                            <h3 className='team-mem-name'>محمود أبو الوفا</h3>
                                            <p className='team-mem-position'>أخصائي مبيعات</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='team-info-wrap-bot'>
                                <h3 className='team-mem-name-bot'>محمود أبو الوفا</h3>
                                <p className='team-mem-position-bot'>أخصائي مبيعات</p>
                                <div className='team-socialIcon-wrap'>
                                    <a href='https://www.linkedin.com/showcase/clinic-unlock/' className='team-social-link' target='_blank'><img src={LinkedInIcon} alt='LinkedIn Icon' className='team-social-icon' /></a>
                                    <a href='https://twitter.com/ClinicUnlock' className='team-social-link' target='_blank'><img src={TwitterIcon} alt='Twitter Icon' className='team-social-icon' /></a>
                                </div>
                            </div>
                        </div>
                        {/* <!-- col end --> */}

                        {/* <!-- col start --> */}
                        <div className='col-lg-3 col-sm-4'>
                            <div className='team-img-wrap'>
                                <img src={Maison} alt='Maison' className='team-img' />
                                <div className='team-main-hover-layer'>
                                    <div className='team-sub-hover-layer'>
                                        <div className='team-info-wrap'>
                                            <h3 className='team-mem-name'>ميسون محمد</h3>
                                            <p className='team-mem-position'>متخصص تصميم الجرافيك</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='team-info-wrap-bot'>
                                <h3 className='team-mem-name-bot'>ميسون محمد</h3>
                                <p className='team-mem-position-bot'>متخصص تصميم الجرافيك</p>
                                <div className='team-socialIcon-wrap'>
                                    <a href='https://www.linkedin.com/showcase/clinic-unlock/' className='team-social-link' target='_blank'><img src={LinkedInIcon} alt='LinkedIn Icon' className='team-social-icon' /></a>
                                    <a href='https://twitter.com/ClinicUnlock' className='team-social-link' target='_blank'><img src={TwitterIcon} alt='Twitter Icon' className='team-social-icon' /></a>
                                </div>
                            </div>
                        </div>
                        {/* <!-- col end --> */}
                        {/* <!-- col start --> */}
                        <div className='col-lg-3 col-sm-4'>
                            <div className='team-img-wrap'>
                                <img src={Abduallah} alt='Abduallah' className='team-img' />
                                <div className='team-main-hover-layer'>
                                    <div className='team-sub-hover-layer'>
                                        <div className='team-info-wrap'>
                                            <h3 className='team-mem-name'>عبد الله ريحان</h3>
                                            <p className='team-mem-position'>مطور تطبيقات الجوال</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='team-info-wrap-bot'>
                                <h3 className='team-mem-name-bot'>عبد الله ريحان</h3>
                                <p className='team-mem-position-bot'>مطور تطبيقات الجوال</p>
                                <div className='team-socialIcon-wrap'>
                                    <a href='https://www.linkedin.com/showcase/clinic-unlock/' className='team-social-link' target='_blank'><img src={LinkedInIcon} alt='LinkedIn Icon' className='team-social-icon' /></a>
                                    <a href='https://twitter.com/ClinicUnlock' className='team-social-link' target='_blank'><img src={TwitterIcon} alt='Twitter Icon' className='team-social-icon' /></a>
                                </div>
                            </div>
                        </div>
                        {/* <!-- col end --> */}
                        {/* <!-- col start --> */}
                        <div className='col-lg-3 col-sm-4'>
                            <div className='team-img-wrap'>
                                <img src={Zahid} alt='Zahid' className='team-img' />
                                <div className='team-main-hover-layer'>
                                    <div className='team-sub-hover-layer'>
                                        <div className='team-info-wrap'>
                                            <h3 className='team-mem-name'>زاهد علي</h3>
                                            <p className='team-mem-position'>مطور ويب شامل</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='team-info-wrap-bot'>
                                <h3 className='team-mem-name-bot'>زاهد علي</h3>
                                <p className='team-mem-position-bot'>مطور ويب شامل</p>
                                <div className='team-socialIcon-wrap'>
                                    <a href='https://www.linkedin.com/showcase/clinic-unlock/' className='team-social-link' target='_blank'><img src={LinkedInIcon} alt='LinkedIn Icon' className='team-social-icon' /></a>
                                    <a href='https://twitter.com/ClinicUnlock' className='team-social-link' target='_blank'><img src={TwitterIcon} alt='Twitter Icon' className='team-social-icon' /></a>
                                </div>
                            </div>
                        </div>
                        {/* <!-- col end --> */}

                        {/* <!-- col start --> */}
                        <div className='col-lg-3 col-sm-4'>
                            <div className='team-img-wrap'>
                                <img src={Sobal} alt='Sobal' className='team-img' />
                                <div className='team-main-hover-layer'>
                                    <div className='team-sub-hover-layer'>
                                        <div className='team-info-wrap'>
                                            <h3 className='team-mem-name'>سوبل نصيب</h3>
                                            <p className='team-mem-position'>مصمم ويب</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='team-info-wrap-bot'>
                                <h3 className='team-mem-name-bot'>سوبل نصيب</h3>
                                <p className='team-mem-position-bot'>مصمم ويب</p>
                                <div className='team-socialIcon-wrap'>
                                    <a href='https://www.linkedin.com/in/sobal/' className='team-social-link' target='_blank'><img src={LinkedInIcon} alt='LinkedIn Icon' className='team-social-icon' /></a>
                                    <a href='https://twitter.com/ClinicUnlock' className='team-social-link' target='_blank'><img src={TwitterIcon} alt='Twitter Icon' className='team-social-icon' /></a>
                                </div>
                            </div>
                        </div>
                        {/* <!-- col end --> */}
                        {/* <!-- col start --> */}
                        <div className='col-lg-3 col-sm-4'>
                            <div className='team-img-wrap'>
                                <img src={Ahmed} alt='Ahmed' className='team-img' />
                                <div className='team-main-hover-layer'>
                                    <div className='team-sub-hover-layer'>
                                        <div className='team-info-wrap'>
                                            <h3 className='team-mem-name'>أحمد فهمي</h3>
                                            <p className='team-mem-position'>مدير حسابات</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='team-info-wrap-bot'>
                                <h3 className='team-mem-name-bot'>أحمد فهمي</h3>
                                <p className='team-mem-position-bot'>مدير حسابات</p>
                                <div className='team-socialIcon-wrap'>
                                    <a href='https://www.linkedin.com/showcase/clinic-unlock/' className='team-social-link' target='_blank'><img src={LinkedInIcon} alt='LinkedIn Icon' className='team-social-icon' /></a>
                                    <a href='https://twitter.com/ClinicUnlock' className='team-social-link' target='_blank'><img src={TwitterIcon} alt='Twitter Icon' className='team-social-icon' /></a>
                                </div>
                            </div>
                        </div>
                        {/* <!-- col end --> */}
                        {/* <!-- col start --> */}
                        <div className='col-lg-3 col-sm-4'>
                            <div className='team-img-wrap'>
                                <img src={Islam} alt='Islam' className='team-img' />
                                <div className='team-main-hover-layer'>
                                    <div className='team-sub-hover-layer'>
                                        <div className='team-info-wrap'>
                                            <h3 className='team-mem-name'>إسلام الدسوقي</h3>
                                            <p className='team-mem-position'>مسؤول نجاح العملاء</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='team-info-wrap-bot'>
                                <h3 className='team-mem-name-bot'>إسلام الدسوقي</h3>
                                <p className='team-mem-position-bot'>مسؤول نجاح العملاء</p>
                                <div className='team-socialIcon-wrap'>
                                    <a href='https://www.linkedin.com/showcase/clinic-unlock/' className='team-social-link' target='_blank'><img src={LinkedInIcon} alt='LinkedIn Icon' className='team-social-icon' /></a>
                                    <a href='https://twitter.com/ClinicUnlock' className='team-social-link' target='_blank'><img src={TwitterIcon} alt='Twitter Icon' className='team-social-icon' /></a>
                                </div>
                            </div>
                        </div>
                        {/* <!-- col end --> */}
                        
                    </div>
                </div>
            </div>
        </section>
    )
}

export default TeamSection