import React from 'react';
import BenefitIcon1 from '../../assets/product-images/green-benefit-icon1.webp';
import BenefitIcon2 from '../../assets/product-images/green-benefit-icon2.webp';
import BenefitIcon3 from '../../assets/product-images/green-benefit-icon3.webp';
import BenefitIcon4 from '../../assets/product-images/green-benefit-icon4.webp';
import BenefitIcon5 from '../../assets/product-images/green-benefit-icon5.webp';
import BenefitIcon6 from '../../assets/product-images/green-benefit-icon6.webp';


const BookBenefitSection = () => {
    return (
        <section className='book-benefit-section arabic'>
            <div className='book-benefit-wrapper'>
                <p className='book-benefit-text'>قنوات <span className='green'>حـــــــجز ذاتي</span> جديدة</p>
                <h3 className='book-benefit-heading' style={{letterSpacing: '0'}}>الفوائد الرئيسية</h3>
                <div className='book-benefit-card-wrapper'>
                    <div className='book-benefit-card'>
                        <div className='book-benefit-card-icon-wrap'><img src={BenefitIcon1} alt='Calender Icon' className='book-benefit-card-icon' /></div>
                        <div className='book-benefit-card-txt'>تحسين <br className='' />تجربة الحجز</div>
                    </div>
                    <div className='book-benefit-card'>
                        <div className='book-benefit-card-icon-wrap'><img src={BenefitIcon2} alt='Brand Icon' className='book-benefit-card-icon' /></div>
                        <div className='book-benefit-card-txt'><b>تعزيز الولاء <br className='' />لعلامتك</b> التجارية</div>
                    </div>
                    <div className='book-benefit-card'>
                        <div className='book-benefit-card-icon-wrap'><img src={BenefitIcon3} alt='Growth Icon' className='book-benefit-card-icon' /></div>
                        <div className='book-benefit-card-txt'>قم <b>بتنمية قنوات <br/>المبيعات</b> الخاصة بك</div>
                    </div>
                    <div className='book-benefit-card'>
                        <div className='book-benefit-card-icon-wrap'><img src={BenefitIcon4} alt='Web Link Icon' className='book-benefit-card-icon' /></div>
                        <div className='book-benefit-card-txt'>إمكانية <b>الربط مع نظام العيادة <br/>المستخدم</b> عن طريق الـ API</div>
                    </div>
                    <div className='book-benefit-card'>
                        <div className='book-benefit-card-icon-wrap'><img src={BenefitIcon5} alt='App CMS Icon' className='book-benefit-card-icon' /></div>
                        <div className='book-benefit-card-txt'>نظام <b>إدارة المحتوى <br/>للتطبيق</b></div>
                    </div>
                    <div className='book-benefit-card'>
                        <div className='book-benefit-card-icon-wrap'><img src={BenefitIcon6} alt='Report Icon' className='book-benefit-card-icon' /></div>
                        <div className='book-benefit-card-txt'><b>لوحة التقارير</b> تساعدك <br/>في التحليل وأخذ القرارات</div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default BookBenefitSection