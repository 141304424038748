import React from 'react';
import ToggleLineL from '../../assets/home-images/toggle-line-l.svg';
import ToggleLineR from '../../assets/home-images/toggle-line-r.svg';
import ClinicLogo from '../../assets/home-images/cliniclogo.svg';
import ClinicLogoAr from '../../assets/home-images/clinic-logo-ar.svg';
import VersatileIcon from '../../assets/home-images/versatile-icon.svg';
import CustomizableIcon from '../../assets/home-images/customizable-icon.svg';
import EfficientIcon from '../../assets/home-images/efficient-icon.svg';
import ReliableIcon from '../../assets/home-images/reliable-icon.svg';
import SatisfyingIcon from '../../assets/home-images/satisfying-icon.svg';
import ProfitableIcon from '../../assets/home-images/profitable-icon.svg';


const ChooseSection = () => {
    return (
        <>
            <section className='choose-section arabic'>
                <div className='choose-wrapper'>
                    <div className='companies-heading-wrap m-0'>
                        <img src={ToggleLineR} alt='Toggle' />
                        <h2 className='clinic-hs-4 m-0 fw-400'><span><img src={ClinicLogoAr} alt='Clinic Unlock' /></span> لماذا تختار</h2>
                        <img src={ToggleLineL} alt='Toggle' />
                    </div>
                    <p className='choose-head-txt clinic-fs-3 text-center mb-5'>لنظام عيادتك؟</p>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-4 col-md-6'>
                                <div className='choose-tile'>
                                    <div className=''>
                                        <img src={VersatileIcon} alt='' className='choose-tile-icon mb-3' />
                                        <h3 className='choose-tile-name clinic-fs-3 fw-6 mb-3'>متعدد الاستخدامات</h3>
                                        <p className='choose-tile-text clinic-fs-5 fw-3'>استخدمه كنظام رئيسي أو ادمجه مع نظامك الحالي بسهولة.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-6'>
                                <div className='choose-tile'>
                                    <div className=''>
                                        <img src={CustomizableIcon} alt='' className='choose-tile-icon mb-3' />
                                        <h3 className='choose-tile-name clinic-fs-3 fw-6 mb-3'>قابل للتخصيص</h3>
                                        <p className='choose-tile-text clinic-fs-5 fw-3'>احصل على تطبيق مخصص يعكس علامتك التجارية وهويتك في ثلاث أسابيع.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-6'>
                                <div className='choose-tile'>
                                    <div className=''>
                                        <img src={EfficientIcon} alt='' className='choose-tile-icon mb-3' />
                                        <h3 className='choose-tile-name clinic-fs-3 fw-6 mb-3'>فعال</h3>
                                        <p className='choose-tile-text clinic-fs-5 fw-3'>وفر الوقت والموارد من خلال تبسيط عملية جدولة المواعيد مع نظامنا الذكي والسهل الاستخدام.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-6'>
                                <div className='choose-tile'>
                                    <div className=''>
                                        <img src={ReliableIcon} alt='' className='choose-tile-icon mb-3' />
                                        <h3 className='choose-tile-name clinic-fs-3 fw-6 mb-3'>موثوق</h3>
                                        <p className='choose-tile-text clinic-fs-5 fw-3'>قلل من الغياب والإلغاءات مع ميزات التذكير وإدارة المواعيد الآلية.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-6'>
                                <div className='choose-tile'>
                                    <div className=''>
                                        <img src={SatisfyingIcon} alt='' className='choose-tile-icon mb-3' />
                                        <h3 className='choose-tile-name clinic-fs-3 fw-6 mb-3'>زيادة الرضا</h3>
                                        <p className='choose-tile-text clinic-fs-5 fw-3'>حسن رضا وولاء المرضى مع تطبيقنا الذي يسهل عليهم حجز المواعيد والوصول إلى سجلاتهم وتقديم الملاحظات.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-6'>
                                <div className='choose-tile'>
                                    <div className=''>
                                        <img src={ProfitableIcon} alt='' className='choose-tile-icon mb-3' />
                                        <h3 className='choose-tile-name clinic-fs-3 fw-6 mb-3'>ربح</h3>
                                        <p className='choose-tile-text clinic-fs-5 fw-3'>زد الإيرادات والنمو مع تطبيقنا الذي يساعدك على خفض التكاليف وزيادة الزيارات وجذب عملاء جدد.</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ChooseSection