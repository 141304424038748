import React, {useEffect } from 'react';
import './Aboutpage.css';
import 'bootstrap/dist/css/bootstrap.css';
import AboutHeroSection from './AboutHeroSection.js';
import TimelineSection from './TimelineSection.js';
import BusinessSection from './BusinessSection.js';
import OfficeSection from './OfficeSection.js';
import PassionateSection from './PassionateSection.js';
import TeamSection from './TeamSection.js';
import AboutCTASection from './AboutCTASection.js';
import {Helmet} from "react-helmet";
import Aos from 'aos';
import 'aos/dist/aos.css';

const AboutPage = () => {
  useEffect(() => {
    Aos.init({duration: 2000});

  }, [])
  return (
    <div>
        <Helmet>
            <title>About Clinic Unlock | Powered by Flash Lead Discover Our Story - Clinic Unlock</title>
            <meta name="description" content="#1 Customizable and Flexible software solutions provider | Clinic Performance Software" />
        </Helmet>

      <AboutHeroSection />
      <TimelineSection />
      <BusinessSection />
      <OfficeSection />
      <PassionateSection />
      <TeamSection />
      <AboutCTASection />
    </div>
    
  )
}

export default AboutPage