import React from 'react';
import SelfBookingImg1 from '../../assets/product-images/selfbooking-img1.webp';
import SelfBookingImg2 from '../../assets/product-images/selfbooking-img2.webp';
import SelfBookingImg3 from '../../assets/product-images/selfbooking-img3.webp';
import SelfBookingToggle1 from '../../assets/product-images/selfbooking-toggle1.webp';
import SelfBookingToggle2 from '../../assets/product-images/selfbooking-toggle2.webp';


const SelfBookingSection = () => {
    return (
        <section className='selfbooking-section arabic' style={{direction: 'ltr'}}>
            <div className='selfbooking-wrapper'>
                <h2 className='selfbooking-heading'>قنوات <span className='selfbooking-spn green'>حـــــــجز ذاتي</span> جديدة</h2>
                <p className='selfbooking-text'>تقدم كلينيك انلوك <b>3 قنوات لأتمتة الحجز والحجــوزات الذاتية</b> التي تسير جنبًا إلى جنب لزيادة حجوزاتك وإيراداتك إلى <br className='hide-mb' />الحد الأقصى مع تقليل العمل اليدوي للموظفين والمهام المتكررة.</p>
                <div className='selfbooking-inner-wrap'>
                    <div className='self-col-wrap'>
                        <div className='self-col-img-wrap'><img src={SelfBookingImg1} alt='Unified WhatsApp' className='self-col-img self-col-img1' /><img src={SelfBookingToggle1} alt='Toggle Line' className='self-toggle-img1' /></div>
                        <h3 className='self-col-heading'>عبر رقم واتساب العيادة الموحد</h3>
                        <p className='self-col-text'>(الوتساب التفاعلي)</p>
                    </div>
                    <div className='self-col-wrap'>
                        <div className='self-col-img-wrap'><img src={SelfBookingImg2} alt='Clinic Website' className='self-col-img self-col-img2' /></div>
                        <h3 className='self-col-heading'>عبر موقع العيادة</h3>
                        <p className='self-col-text'>(صفحة على الموقع)</p>
                    </div>
                    <div className='self-col-wrap'>
                        <div className='self-col-img-wrap'><img src={SelfBookingImg3} alt='Mobile App' className='self-col-img self-col-img3' /><img src={SelfBookingToggle2} alt='Toggle Line' className='self-toggle-img2' /></div>
                        <h3 className='self-col-heading'>عبر تطبيق جوال العيادة الرسمي</h3>
                        <p className='self-col-text'>(يتيح تطبيق كامل للعيادة خلال أيام)</p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default SelfBookingSection