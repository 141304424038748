import React from 'react';
import TimelineMbImg from '../../assets/about-images/about-timeline-mb.webp';

const TimelineSection = () => {
    return (
        <section className='about-timeline'>
            <div className='about-timeline-wrapper'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-6'>
                            <h2 className='about-time-heading fw-6 clinic-hs-4'>We helped <span className='blue'>hundreds</span> of SMEs</h2>
                            <p className='about-time-heading clinic-fs-5 fw-4'>We launched in early 2021 with a focus on small and medium-sized companies (SMEs). Our Sales and Customer Support products have helped hundreds of SMEs to improve their sales performance and customer service.</p>
                        </div>
                        <div className='col-lg-4'><div className='about-timeline-img-wrap only-mob'><img src={TimelineMbImg} alt='' className='about-timeline-mb' /></div></div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default TimelineSection