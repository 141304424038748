import React from 'react';
import ToggleLineL from '../../assets/home-images/toggle-line-l.svg';
import ToggleLineR from '../../assets/home-images/toggle-line-r.svg';
import ClinicLogo from '../../assets/home-images/cliniclogo.svg';
import ClinicLogoAr from '../../assets/home-images/clinic-logo-ar.svg';
import VersatileIcon from '../../assets/home-images/versatile-icon.svg';
import CustomizableIcon from '../../assets/home-images/customizable-icon.svg';
import EfficientIcon from '../../assets/home-images/efficient-icon.svg';
import ReliableIcon from '../../assets/home-images/reliable-icon.svg';
import SatisfyingIcon from '../../assets/home-images/satisfying-icon.svg';
import ProfitableIcon from '../../assets/home-images/profitable-icon.svg';


const ChooseSection = () => {
    return (
        <>
            <section className='choose-section'>
                <div className='choose-wrapper'>
                    <div className='companies-heading-wrap m-0'>
                        <img src={ToggleLineL} alt='Toggle' />
                        <h2 className='clinic-hs-4 m-0 fw-400'>Why choose <span><img src={ClinicLogo} alt='Clinic Unlock' /></span></h2>
                        <img src={ToggleLineR} alt='Toggle' />
                    </div>
                    <p className='choose-head-txt clinic-fs-3 text-center mb-5'>as your clinic system?</p>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-4 col-md-6'>
                                <div className='choose-tile'>
                                    <div className=''>
                                        <img src={VersatileIcon} alt='' className='choose-tile-icon mb-3' />
                                        <h3 className='choose-tile-name clinic-fs-3 fw-5 mb-3'>Versatile</h3>
                                        <p className='choose-tile-text clinic-fs-5'>use it as your main system to integrate with your current system</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-6'>
                                <div className='choose-tile'>
                                    <div className=''>
                                        <img src={CustomizableIcon} alt='' className='choose-tile-icon mb-3' />
                                        <h3 className='choose-tile-name clinic-fs-3 fw-5 mb-3'>Customizable</h3>
                                        <p className='choose-tile-text clinic-fs-5'>have a white label app that reflects your clinic’s brand and identity in 3 weeks.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-6'>
                                <div className='choose-tile'>
                                    <div className=''>
                                        <img src={EfficientIcon} alt='' className='choose-tile-icon mb-3' />
                                        <h3 className='choose-tile-name clinic-fs-3 fw-5 mb-3'>Efficient</h3>
                                        <p className='choose-tile-text clinic-fs-5'>save time and resources Clinic Unlock helps you to save time and resources by streamlining the appointment scheduling process.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-6'>
                                <div className='choose-tile'>
                                    <div className=''>
                                        <img src={ReliableIcon} alt='' className='choose-tile-icon mb-3' />
                                        <h3 className='choose-tile-name clinic-fs-3 fw-5 mb-3'>Reliable</h3>
                                        <p className='choose-tile-text clinic-fs-5'>reduce no shows and cancellations with our automated reminders and slot management features.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-6'>
                                <div className='choose-tile'>
                                    <div className=''>
                                        <img src={SatisfyingIcon} alt='' className='choose-tile-icon mb-3' />
                                        <h3 className='choose-tile-name clinic-fs-3 fw-5 mb-3'>Satisfying</h3>
                                        <p className='choose-tile-text clinic-fs-5'> improve patient satisfaction with our app that makes it easier for patients to book appointments and manage their care.</p>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-6'>
                                <div className='choose-tile'>
                                    <div className=''>
                                        <img src={ProfitableIcon} alt='' className='choose-tile-icon mb-3' />
                                        <h3 className='choose-tile-name clinic-fs-3 fw-5 mb-3'>Profitable</h3>
                                        <p className='choose-tile-text clinic-fs-5'> increase revenue and growth with our app that helps you reduce costs, increase visits and attract new clients.</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default ChooseSection