import './App.css';
import Navbar from "./components/Header/Header.jsx";
import NavbarEN from "./components/HeaderEn/Header-en.jsx";
import {
    BrowserRouter as Router,
    Routes,
    Route,
} from "react-router-dom";
import Home from "./pages/index.js";
import HomeEN from "./pages/index-en.js";
import About from "./pages/about.js";
import AboutEN from "./pages/about-en.js";
import Product from "./pages/product.js";
import ProductEN from "./pages/product-en.js";
import Contact from './pages/contact.js';
import ContactEN from './pages/contact-en.js';
import Privacy from './pages/privacy';
import PrivacyEN from './pages/privacy-en';
import Footer from './components/Footer/Footer.js';
import FooterEN from './components/FooterEn/Footer-en.js';
import DeleteEN from './pages/delete-account-en';


function App() {
    if(window.location.pathname.includes('/en/') ){
        return (
            <Router>
                <NavbarEN/>

                <Routes>
                    <Route index element={<Home/>} title="Index Page" />
                    <Route path="/en/" element={<HomeEN/>} title="Home Page" />
                    <Route path="/en" element={<HomeEN/>} title="Home Page" />
                    <Route path="/about" element={<About/>}/>
                    <Route path="/en/about" element={<AboutEN/>}/>
                    <Route path="/en/about.php" element={<AboutEN/>}/>
                    <Route path="/product" element={<Product/>}/>
                    <Route path="/en/product" element={<ProductEN/>}/>
                    <Route path="/en/product.php" element={<ProductEN/>}/>
                    <Route path="/contact-us" element={<Contact/>}/>
                    <Route path="/en/contact-us" element={<ContactEN/>}/>
                    <Route path="/en/contact-us.php" element={<ContactEN/>}/>
                    <Route path="/privacy" element={<Privacy/>}/>
                    <Route path="/en/privacy" element={<PrivacyEN/>}/>
                    <Route path="/en/delete-account" element={<DeleteEN/>}/>
                    <Route path="/delete-account" element={<DeleteEN/>}/>
                </Routes>
                <FooterEN/>
            </Router>
        );
    }else {
        return (
            <Router>
                <Navbar/>

                <Routes>
                    <Route index element={<Home/>}/>
                    <Route path="/en" element={<HomeEN/>}/>
                    <Route path="/about" element={<About/>}/>
                    <Route path="/en/about" element={<AboutEN/>}/>
                    <Route path="/product" element={<Product/>}/>
                    <Route path="/en/product" element={<ProductEN/>}/>
                    <Route path="/contact-us" element={<Contact/>}/>
                    <Route path="/en/contact-us" element={<ContactEN/>}/>
                    <Route path="/privacy" element={<Privacy/>}/>
                    <Route path="/en/privacy" element={<PrivacyEN/>}/>
                    <Route path="/delete-account" element={<DeleteEN/>}/>
                </Routes>
                <Footer/>
            </Router>
        );
    }
}

export default App;
