import React, {useEffect } from 'react';
import './Homepage.css';
import 'bootstrap/dist/css/bootstrap.css';
import HeroSection from './HeroSection';
import MapSection from './MapSection';
import BenefitSection from './BenefitSection';
import BookingChennelSection from './BookingChennelSection';
import PreventionSection from './PreventionSection';
import JourneySection from './JourneySection';
import CTASection from './CTASection';
import CompaniesSection from './CompaniesSection';
import ChooseSection from './ChooseSection';
import CTABottomSection from './CTABottomSection';
import {Helmet} from "react-helmet";
import Aos from 'aos';
import 'aos/dist/aos.css';

const Homepage = () => {
  useEffect(() => {
    Aos.init({duration: 2000});
  }, [])
  return (
    <div>
        <Helmet>
            <title>Clinic Unlock - Clinic Performance Software</title>
            <meta name="description" content="Optimize clinic performance with our powerful tailored software solutions | Clinic Unlock" />
        </Helmet>
      <HeroSection />
      <MapSection />
      <BenefitSection />
      <BookingChennelSection />
      <PreventionSection />
      <JourneySection />
      <CTASection />
      <CompaniesSection />
      <ChooseSection />
      <CTABottomSection />
    </div>
    
  )
}

export default Homepage