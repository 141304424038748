import React from 'react'
import Usericon from '../../assets/home-images/user-icon.svg'
import Clienticon from '../../assets/home-images/client-icon.svg'
import Locationicon from '../../assets/home-images/location-icon.svg'
import NumberMap from '../../assets/home-images/Numbers-Map.svg'



const MapSection = () => {
    return (
        <>
            <section className='map-section'>
                <div className='map-wrapper'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-5'>
                                <div className='map-text-wrap'>
                                    <span className='map-txt-icon me-2'><img src={Usericon} alt='' /></span>
                                    <p className='map-text clinic-fs-1 m-0'>10 years of expertise</p>
                                </div>
                                <div className='map-text-wrap'>
                                    <span className='map-txt-icon me-2'><img src={Clienticon} alt='' /></span>
                                    <p className='map-text clinic-fs-1 m-0'>+100 Clients worldwide</p>
                                </div>
                                <div className='map-text-wrap m-0'>
                                    <span className='map-txt-icon me-2'><img src={Locationicon} alt='' /></span>
                                    <p className='map-text clinic-fs-1 m-0'><small className='map-samll-txt clinic-fs-6'>Offices in the</small> USA, Pakistan, Egypt and KSA</p>
                                </div>
                            </div>
                            <div className='col-lg-7'><div className='map-image-wrap'><img src={NumberMap} alt='' className='map-image w-100' /></div></div>
                        </div>
                    </div>
                </div>
            </section>
        </>
            
    )
}

export default MapSection
