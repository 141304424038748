import React from 'react'

const privacy = () => {
  return (
    <div className="office-wrapper arabic" style={{padding: "4rem 0 0 0"}}>
        <section className='privacy-section'>
            <h1>سياسة الخصوصية كلينيك أنلوك</h1>
            <h4>بيان سياسة الخصوصية التطبيقات والوظائف الإضافية و ملحقاتها لشركة كلينيك أنلوك</h4>
            <p>في Clinicunlock.com، تعد الثقة والشفافية إحدى القيم الرئيسية. يساعد هذا البيان لسياسة الخصوصية إدارة الخدمات التي نقدمها عن طريق  تطبيق الهاتف المحمول كلينيك أنلوك أو المنصات الإضافية أو التطبيقات الاخرى  ("التطبيق") ، بالإضافة إلى أعمالهم وعلاقاتهم مع بيانات العملاء.</p>
            <h4>البيانات الشخصية وبيانات العملاء</h4>
            <p>تعتبر البيانات الشخصية والبيانات الأخرى المقدمة من العميل أو بالنيابة عنه إلى الخدمات من خلال التطبيق من خلال كلينيك أنلوك "بيانات العميل". و كما هو متفق عليه بين العميل وكلينيك أنلوك، يكون كل عميل هو المتحكم في "بيانات العميل" الخاصة به ولا تقوم كلينيك أنلوك  بالاطلاع أو  معالجة بيانات العميل هذه بأي وسيلة.</p>
            <h4>حذف بيانات العميل</h4>
            <p>يمكن لأي مستخدم لتطبيقنا حذف "بيانات العميل" الخاصة به في أي وقت خلال فترة نشاطه أو بعد انتهاء صلاحية الحساب. إذا لم يقم المستخدم بحذف "بيانات العميل" خلال 90 يومًا من انتهاء الصلاحية، فيمكن لـكلينيك أنلوك  مسح جميع الحسابات "بيانات العميل" بشكل دائم دون الاحتفاظ بأي نسخ احتياطية.</p>
            <h3>ما هي البيانات التي نجمعها؟</h3>
            <h4>البيانات الشخصية التي يتم جمعها منك مباشرةً</h4>
            <p>فيما يتعلق بتنزيل التطبيق واستخدامه، قد نقوم بجمع معلومات حساب عامة عنك خلال فتح الحساب مثل اسمك واسم المستخدم وعنوان البريد الإلكتروني والعنوان الفعلي ورقم الهاتف وتاريخ الميلاد والمسمى الوظيفي والمعلومات الأخرى التي تقدمها.</p>
            <p>قد نستخدم تلك البيانات الشخصية لتعزيز تجربة العملاء والتسويق.</p>
            <h4>كيف نقوم بتأمين بياناتك الشخصية أو بيانات العملاء</h4>
            <p>تتخذ كلينيك أنلوك خطوات معقولة ومناسبة لحماية أمن البيانات الشخصية من الوصول غير المصرح به أو غير القانوني أو استخدامها أو إتلافها أو فقدانها أو تغييرها أو الكشف عنها</p>
        </section>
    </div>
  )
}

export default privacy