import React from 'react';
import Alaa from '../../assets/about-images/Alaa.webp';
import Jackson from '../../assets/about-images/jackson-harry.webp';
import Caroline from '../../assets/about-images/Caroline.webp';
import Irfan from '../../assets/about-images/Irfan.webp';
import Salaser from '../../assets/about-images/Salaser.webp';
import Rabie from '../../assets/about-images/Mohamed-Rabie.webp';
import Fishawy from '../../assets/about-images/Fishawy.webp';
import Mahmoud from '../../assets/about-images/Mahmoud.webp';
import Maison from '../../assets/about-images/Maison1.webp';
import Abduallah from '../../assets/about-images/Abduallah.webp';
import Zahid from '../../assets/about-images/Zahid.webp';
import Sobal from '../../assets/about-images/Sobal1.webp';
import Ahmed from '../../assets/about-images/ahmed.webp';
import Islam from '../../assets/about-images/islam.webp';
import LinkedInIcon from '../../assets/about-images/linkedin-icon-black.webp';
import TwitterIcon from '../../assets/about-images/x-icon-black.webp';


const TeamSection = () => {
    return (
        <section className='team-section'>
            <div className='team-main-wrapper'>
                <div className='container'>
                    <div className='row team-row'>
                        {/* <!-- col start --> */}
                        <div className='col-lg-12'>
                            <div className='team-heading-wrap'>
                                <h2 className='team-heading'><span className='team-head-spn blue'>Team</span> Behind Clinic Unlock</h2>
                                <p className='team-head-text'>+ 4 Master degree in AI & Data science</p>
                            </div>
                        </div>
                        {/* <!-- col end --> */}
                        {/* <!-- col start --> */}
                        <div className='col-lg-3 col-sm-4'>
                            <div className='team-img-wrap'>
                                <img src={Alaa} alt='Alaa Mourad' className='team-img' />
                                <div className='team-main-hover-layer'>
                                    <div className='team-sub-hover-layer'>
                                        <div className='Team-social-wrap'>
                                            <a href='https://www.linkedin.com/in/alaa-mourad-79106635/' className='team-social-link' target='_blank'><img src={LinkedInIcon} alt='LinkedIn Icon' className='team-social-icon' /></a>
                                            <a href='https://twitter.com/ClinicUnlock' className='team-social-link' target='_blank'><img src={TwitterIcon} alt='Twitter Icon' className='team-social-icon' /></a>
                                        </div>
                                        <div className='team-info-wrap'>
                                            <h3 className='team-mem-name'>Alaa Mourad</h3>
                                            <p className='team-mem-position'>Co-Founder & CEO</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='team-info-wrap-bot'>
                                <h3 className='team-mem-name-bot'>Alaa Mourad</h3>
                                <p className='team-mem-position-bot'>Co-Founder & CEO</p>
                                <div className='team-socialIcon-wrap'>
                                    <a href='https://www.linkedin.com/in/alaa-mourad-79106635/' className='team-social-link' target='_blank'><img src={LinkedInIcon} alt='LinkedIn Icon' className='team-social-icon' /></a>
                                    <a href='https://twitter.com/ClinicUnlock' className='team-social-link' target='_blank'><img src={TwitterIcon} alt='Twitter Icon' className='team-social-icon' /></a>
                                </div>
                            </div>
                        </div>
                        {/* <!-- col end --> */}
                        {/* <!-- col start --> */}
                        <div className='col-lg-3 col-sm-4'>
                            <div className='team-img-wrap'>
                                <img src={Jackson} alt='Jackson Harry' className='team-img' />
                                <div className='team-main-hover-layer'>
                                    <div className='team-sub-hover-layer'>
                                        <div className='Team-social-wrap'>
                                            <a href='https://www.linkedin.com/in/jackson-harry/' className='team-social-link' target='_blank'><img src={LinkedInIcon} alt='LinkedIn Icon' className='team-social-icon' /></a>
                                            <a href='https://twitter.com/ClinicUnlock' className='team-social-link' target='_blank'><img src={TwitterIcon} alt='Twitter Icon' className='team-social-icon' /></a>
                                        </div>
                                        <div className='team-info-wrap'>
                                            <h3 className='team-mem-name'>Jackson Harry</h3>
                                            <p className='team-mem-position'>Co-Founder & CTO</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='team-info-wrap-bot'>
                                <h3 className='team-mem-name-bot'>Jackson Harry</h3>
                                <p className='team-mem-position-bot'>Co-Founder & CTO</p>
                                <div className='team-socialIcon-wrap'>
                                    <a href='https://www.linkedin.com/in/jackson-harry/' className='team-social-link' target='_blank'><img src={LinkedInIcon} alt='LinkedIn Icon' className='team-social-icon' /></a>
                                    <a href='https://twitter.com/ClinicUnlock' className='team-social-link' target='_blank'><img src={TwitterIcon} alt='Twitter Icon' className='team-social-icon' /></a>
                                </div>
                            </div>
                        </div>
                        {/* <!-- col end --> */}
                        {/* <!-- col start --> */}
                        <div className='col-lg-3 col-sm-4'>
                            <div className='team-img-wrap'>
                                <img src={Caroline} alt='Caroline' className='team-img' />
                                <div className='team-main-hover-layer'>
                                    <div className='team-sub-hover-layer'>
                                        <div className='Team-social-wrap'>
                                            <a href='https://www.linkedin.com/in/caroline-robert1/' className='team-social-link' target='_blank'><img src={LinkedInIcon} alt='LinkedIn Icon' className='team-social-icon' /></a>
                                            <a href='https://twitter.com/ClinicUnlock' className='team-social-link' target='_blank'><img src={TwitterIcon} alt='Twitter Icon' className='team-social-icon' /></a>
                                        </div>
                                        <div className='team-info-wrap'>
                                            <h3 className='team-mem-name'>Caroline Robert</h3>
                                            <p className='team-mem-position'>Marketing Manager</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='team-info-wrap-bot'>
                                <h3 className='team-mem-name-bot'>Caroline Robert</h3>
                                <p className='team-mem-position-bot'>Marketing Manager</p>
                                <div className='team-socialIcon-wrap'>
                                    <a href='https://www.linkedin.com/in/caroline-robert1/' className='team-social-link' target='_blank'><img src={LinkedInIcon} alt='LinkedIn Icon' className='team-social-icon' /></a>
                                    <a href='https://twitter.com/ClinicUnlock' className='team-social-link' target='_blank'><img src={TwitterIcon} alt='Twitter Icon' className='team-social-icon' /></a>
                                </div>
                            </div>
                        </div>
                        {/* <!-- col end --> */}
                        {/* <!-- col start --> */}
                        <div className='col-lg-3 col-sm-4'>
                            <div className='team-img-wrap'>
                                <img src={Irfan} alt='Irfan' className='team-img' />
                                <div className='team-main-hover-layer'>
                                    <div className='team-sub-hover-layer'>
                                        <div className='Team-social-wrap'>
                                            <a href='https://www.linkedin.com/in/irfan-bari/' className='team-social-link' target='_blank'><img src={LinkedInIcon} alt='LinkedIn Icon' className='team-social-icon' /></a>
                                            <a href='https://twitter.com/ClinicUnlock' className='team-social-link' target='_blank'><img src={TwitterIcon} alt='Twitter Icon' className='team-social-icon' /></a>
                                        </div>
                                        <div className='team-info-wrap'>
                                            <h3 className='team-mem-name'>Irfan Baree</h3>
                                            <p className='team-mem-position'>Technology Manager</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='team-info-wrap-bot'>
                                <h3 className='team-mem-name-bot'>Irfan Baree</h3>
                                <p className='team-mem-position-bot'>Technology Manager</p>
                                <div className='team-socialIcon-wrap'>
                                    <a href='https://www.linkedin.com/in/irfan-bari/' className='team-social-link' target='_blank'><img src={LinkedInIcon} alt='LinkedIn Icon' className='team-social-icon' /></a>
                                    <a href='https://twitter.com/ClinicUnlock' className='team-social-link' target='_blank'><img src={TwitterIcon} alt='Twitter Icon' className='team-social-icon' /></a>
                                </div>
                            </div>
                        </div>
                        {/* <!-- col end --> */}
                        {/* <!-- col start --> */}
                        <div className='col-lg-3 col-sm-4'>
                            <div className='team-img-wrap'>
                                <img src={Salaser} alt='Salaser' className='team-img' />
                                <div className='team-main-hover-layer'>
                                    <div className='team-sub-hover-layer'>
                                        <div className='Team-social-wrap'>
                                            <a href='https://www.linkedin.com/in/slaser/' className='team-social-link' target='_blank'><img src={LinkedInIcon} alt='LinkedIn Icon' className='team-social-icon' /></a>
                                            <a href='https://twitter.com/ClinicUnlock' className='team-social-link' target='_blank'><img src={TwitterIcon} alt='Twitter Icon' className='team-social-icon' /></a>
                                        </div>
                                        <div className='team-info-wrap'>
                                            <h3 className='team-mem-name'>Salaser Babu</h3>
                                            <p className='team-mem-position'>Technical Project Manager</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='team-info-wrap-bot'>
                                <h3 className='team-mem-name-bot'>Salaser Babu</h3>
                                <p className='team-mem-position-bot'>Technical Project Manager</p>
                                <div className='team-socialIcon-wrap'>
                                    <a href='https://www.linkedin.com/in/slaser/' className='team-social-link' target='_blank'><img src={LinkedInIcon} alt='LinkedIn Icon' className='team-social-icon' /></a>
                                    <a href='https://twitter.com/ClinicUnlock' className='team-social-link' target='_blank'><img src={TwitterIcon} alt='Twitter Icon' className='team-social-icon' /></a>
                                </div>
                            </div>
                        </div>
                        {/* <!-- col end --> */}
                        {/* <!-- col start --> */}
                        <div className='col-lg-3 col-sm-4'>
                            <div className='team-img-wrap'>
                                <img src={Rabie} alt='Rabie' className='team-img' />
                                <div className='team-main-hover-layer'>
                                    <div className='team-sub-hover-layer'>
                                        <div className='team-info-wrap'>
                                            <h3 className='team-mem-name'>Mohamed Rabie</h3>
                                            <p className='team-mem-position'>Sales Team Leader</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='team-info-wrap-bot'>
                                <h3 className='team-mem-name-bot'>Mohamed Rabie</h3>
                                <p className='team-mem-position-bot'>Sales Team Leader</p>
                                <div className='team-socialIcon-wrap'>
                                    <a href='https://www.linkedin.com/showcase/clinic-unlock/' className='team-social-link' target='_blank'><img src={LinkedInIcon} alt='LinkedIn Icon' className='team-social-icon' /></a>
                                    <a href='https://twitter.com/ClinicUnlock' className='team-social-link' target='_blank'><img src={TwitterIcon} alt='Twitter Icon' className='team-social-icon' /></a>
                                </div>
                            </div>
                        </div>
                        {/* <!-- col end --> */}
                        {/* <!-- col start --> */}
                        <div className='col-lg-3 col-sm-4'>
                            <div className='team-img-wrap'>
                                <img src={Fishawy} alt='Fishawy' className='team-img' />
                                <div className='team-main-hover-layer'>
                                    <div className='team-sub-hover-layer'>
                                        <div className='team-info-wrap'>
                                            <h3 className='team-mem-name'>Mohamed Elfishawy</h3>
                                            <p className='team-mem-position'>Senior Product Designer</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='team-info-wrap-bot'>
                                <h3 className='team-mem-name-bot'>Mohamed Elfishawy</h3>
                                <p className='team-mem-position-bot'>Senior Product Designer</p>
                                <div className='team-socialIcon-wrap'>
                                    <a href='https://www.linkedin.com/showcase/clinic-unlock/' className='team-social-link' target='_blank'><img src={LinkedInIcon} alt='LinkedIn Icon' className='team-social-icon' /></a>
                                    <a href='https://twitter.com/ClinicUnlock' className='team-social-link' target='_blank'><img src={TwitterIcon} alt='Twitter Icon' className='team-social-icon' /></a>
                                </div>
                            </div>
                        </div>
                        {/* <!-- col end --> */}
                        {/* <!-- col start --> */}
                        <div className='col-lg-3 col-sm-4'>
                            <div className='team-img-wrap'>
                                <img src={Mahmoud} alt='Mahmoud' className='team-img' />
                                <div className='team-main-hover-layer'>
                                    <div className='team-sub-hover-layer'>
                                        <div className='team-info-wrap'>
                                            <h3 className='team-mem-name'>Mahmoud Aboelwafa</h3>
                                            <p className='team-mem-position'>Business Developer</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='team-info-wrap-bot'>
                                <h3 className='team-mem-name-bot'>Mahmoud Aboelwafa</h3>
                                <p className='team-mem-position-bot'>Business Developer</p>
                                <div className='team-socialIcon-wrap'>
                                    <a href='https://www.linkedin.com/showcase/clinic-unlock/' className='team-social-link' target='_blank'><img src={LinkedInIcon} alt='LinkedIn Icon' className='team-social-icon' /></a>
                                    <a href='https://twitter.com/ClinicUnlock' className='team-social-link' target='_blank'><img src={TwitterIcon} alt='Twitter Icon' className='team-social-icon' /></a>
                                </div>
                            </div>
                        </div>
                        {/* <!-- col end --> */}

                        {/* <!-- col start --> */}
                        <div className='col-lg-3 col-sm-4'>
                            <div className='team-img-wrap'>
                                <img src={Maison} alt='Maison' className='team-img' />
                                <div className='team-main-hover-layer'>
                                    <div className='team-sub-hover-layer'>
                                        <div className='team-info-wrap'>
                                            <h3 className='team-mem-name'>Maison Mohamed</h3>
                                            <p className='team-mem-position'>Graphic Designer</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='team-info-wrap-bot'>
                                <h3 className='team-mem-name-bot'>Maison Mohamed</h3>
                                <p className='team-mem-position-bot'>Graphic Designer</p>
                                <div className='team-socialIcon-wrap'>
                                    <a href='https://www.linkedin.com/showcase/clinic-unlock/' className='team-social-link' target='_blank'><img src={LinkedInIcon} alt='LinkedIn Icon' className='team-social-icon' /></a>
                                    <a href='https://twitter.com/ClinicUnlock' className='team-social-link' target='_blank'><img src={TwitterIcon} alt='Twitter Icon' className='team-social-icon' /></a>
                                </div>
                            </div>
                        </div>
                        {/* <!-- col end --> */}
                        {/* <!-- col start --> */}
                        <div className='col-lg-3 col-sm-4'>
                            <div className='team-img-wrap'>
                                <img src={Abduallah} alt='Abduallah' className='team-img' />
                                <div className='team-main-hover-layer'>
                                    <div className='team-sub-hover-layer'>
                                        <div className='team-info-wrap'>
                                            <h3 className='team-mem-name'>Abduallah Rehan</h3>
                                            <p className='team-mem-position'>Application Developer</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='team-info-wrap-bot'>
                                <h3 className='team-mem-name-bot'>Abduallah Rehan</h3>
                                <p className='team-mem-position-bot'>Application Developer</p>
                                <div className='team-socialIcon-wrap'>
                                    <a href='https://www.linkedin.com/showcase/clinic-unlock/' className='team-social-link' target='_blank'><img src={LinkedInIcon} alt='LinkedIn Icon' className='team-social-icon' /></a>
                                    <a href='https://twitter.com/ClinicUnlock' className='team-social-link' target='_blank'><img src={TwitterIcon} alt='Twitter Icon' className='team-social-icon' /></a>
                                </div>
                            </div>
                        </div>
                        {/* <!-- col end --> */}
                        {/* <!-- col start --> */}
                        <div className='col-lg-3 col-sm-4'>
                            <div className='team-img-wrap'>
                                <img src={Zahid} alt='Zahid' className='team-img' />
                                <div className='team-main-hover-layer'>
                                    <div className='team-sub-hover-layer'>
                                        <div className='team-info-wrap'>
                                            <h3 className='team-mem-name'>Zahid Ali</h3>
                                            <p className='team-mem-position'>Full Stack Developer</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='team-info-wrap-bot'>
                                <h3 className='team-mem-name-bot'>Zahid Ali</h3>
                                <p className='team-mem-position-bot'>Full Stack Developer</p>
                                <div className='team-socialIcon-wrap'>
                                    <a href='https://www.linkedin.com/showcase/clinic-unlock/' className='team-social-link' target='_blank'><img src={LinkedInIcon} alt='LinkedIn Icon' className='team-social-icon' /></a>
                                    <a href='https://twitter.com/ClinicUnlock' className='team-social-link' target='_blank'><img src={TwitterIcon} alt='Twitter Icon' className='team-social-icon' /></a>
                                </div>
                            </div>
                        </div>
                        {/* <!-- col end --> */}

                        {/* <!-- col start --> */}
                        <div className='col-lg-3 col-sm-4'>
                            <div className='team-img-wrap'>
                                <img src={Sobal} alt='Sobal' className='team-img' />
                                <div className='team-main-hover-layer'>
                                    <div className='team-sub-hover-layer'>
                                        <div className='team-info-wrap'>
                                            <h3 className='team-mem-name'>Sobal Naseeb</h3>
                                            <p className='team-mem-position'>Web Developer</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='team-info-wrap-bot'>
                                <h3 className='team-mem-name-bot'>Sobal Naseeb</h3>
                                <p className='team-mem-position-bot'>Web Developer</p>
                                <div className='team-socialIcon-wrap'>
                                    <a href='https://www.linkedin.com/in/sobal/' className='team-social-link' target='_blank'><img src={LinkedInIcon} alt='LinkedIn Icon' className='team-social-icon' /></a>
                                    <a href='https://twitter.com/ClinicUnlock' className='team-social-link' target='_blank'><img src={TwitterIcon} alt='Twitter Icon' className='team-social-icon' /></a>
                                </div>
                            </div>
                        </div>
                        {/* <!-- col end --> */}
                        {/* <!-- col start --> */}
                        <div className='col-lg-3 col-sm-4'>
                            <div className='team-img-wrap'>
                                <img src={Ahmed} alt='Ahmed' className='team-img' />
                                <div className='team-main-hover-layer'>
                                    <div className='team-sub-hover-layer'>
                                        <div className='team-info-wrap'>
                                            <h3 className='team-mem-name'>Ahmed Fahmi</h3>
                                            <p className='team-mem-position'>Senior Accountant</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='team-info-wrap-bot'>
                                <h3 className='team-mem-name-bot'>Ahmed Fahmi</h3>
                                <p className='team-mem-position-bot'>Senior Accountant</p>
                                <div className='team-socialIcon-wrap'>
                                    <a href='https://www.linkedin.com/showcase/clinic-unlock/' className='team-social-link' target='_blank'><img src={LinkedInIcon} alt='LinkedIn Icon' className='team-social-icon' /></a>
                                    <a href='https://twitter.com/ClinicUnlock' className='team-social-link' target='_blank'><img src={TwitterIcon} alt='Twitter Icon' className='team-social-icon' /></a>
                                </div>
                            </div>
                        </div>
                        {/* <!-- col end --> */}
                        {/* <!-- col start --> */}
                        <div className='col-lg-3 col-sm-4'>
                            <div className='team-img-wrap'>
                                <img src={Islam} alt='Islam' className='team-img' />
                                <div className='team-main-hover-layer'>
                                    <div className='team-sub-hover-layer'>
                                        <div className='team-info-wrap'>
                                            <h3 className='team-mem-name'>Islam El-Desouky</h3>
                                            <p className='team-mem-position'>Customer Success</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='team-info-wrap-bot'>
                                <h3 className='team-mem-name-bot'>Islam El-Desouky</h3>
                                <p className='team-mem-position-bot'>Customer Success</p>
                                <div className='team-socialIcon-wrap'>
                                    <a href='https://www.linkedin.com/showcase/clinic-unlock/' className='team-social-link' target='_blank'><img src={LinkedInIcon} alt='LinkedIn Icon' className='team-social-icon' /></a>
                                    <a href='https://twitter.com/ClinicUnlock' className='team-social-link' target='_blank'><img src={TwitterIcon} alt='Twitter Icon' className='team-social-icon' /></a>
                                </div>
                            </div>
                        </div>
                        {/* <!-- col end --> */}
                        
                    </div>
                </div>
            </div>
        </section>
    )
}

export default TeamSection