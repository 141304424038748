import React from 'react';

const PassionateSection = () => {
    return (
        <section className='passionate-section'>
            <div className='passionate-wrapper'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-5'>
                            <div className='nb-abt-office-wrap'>
                                <h2 className='passion-heading clinic-hs-4 fw-6'>We're passionate about <br className='hide-br-mb' /><span className='blue'>helping businesses to succeed</span></h2>
                                <p className='passion-txt clinic-fs-5 fw-4'>We believe that our software solutions can make a real difference in the lives of our customers. That's why we're committed to providing our customers with the best possible products and support.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default PassionateSection