import React, {useEffect } from 'react';
import $ from 'jquery';
import ReCAPTCHA from "react-google-recaptcha";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import './Contactpage.css';
import 'bootstrap/dist/css/bootstrap.css';
import Aos from 'aos';
import 'aos/dist/aos.css';
import {Helmet} from "react-helmet";
import { useSearchParams } from "react-router-dom";

const ContactUs = () => {
    const chkbox = true;
  useEffect(() => {
    Aos.init({duration: 2000});
  }, [])

    const [searchParams, setSearchParams] = useSearchParams();
    let email = searchParams.get("email");

    const handleSubmit = (e) => {
        e.preventDefault();
        $.ajax({
            url: `/contact-sub2.php`,
            dataType: "JSON",
            method: 'POST',
            cache: false,
            data: $('#contactForm').serialize(),
            success: function (data) {
                if(data.hasOwnProperty('error'))
                    alert(data.error);
                else {
                    window.location.href = "/contact-us?email="+$('#conEmail').val();
                }
            }.bind(this),
            error: function (xhr, status, err) {
                alert(err);
            },
        });
    }

    const handleIncomplete = (e) => {
        $.ajax({
            url: `/incompleteForm.php`,
            dataType: "JSON",
            method: 'POST',
            cache: false,
            data: $('#contactForm').serialize(),
            success: function (data) {
                $('#ic_id').val(data.result_id);
            }.bind(this),
            error: function (xhr, status, err) {
                //do nothing
            },
        });
    }

    if (email !== '' && email !== null) {
        return (
            <div>
                <Helmet>
                    <title>كلينيك انلوك - تواصل معنا- برنامج تطوير اداء العيادات</title>
                    <meta name="description"
                          content="Minimize manual errors and enhance productivity with Clinic Unlock performance software"/>
                    <style>
                        {'.react-tel-input .selected-flag{direction: ltr'}
                    </style>
                </Helmet>
                <section className='contact-section'>
                    <div className='contact-wrapper'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-lg-12'>
                                    <div className='contact-form-main-wrap arabic'>
                                        <div className={"alert alert-success"}>شكرا لك على التقديم! سوف يقوم وكلاؤنا بالتواصل معك في أقرب وقت ممكن.</div>
                                        <form id='contactForm' method='POST' onSubmit={handleSubmit}>
                                            <div className='contact-form-col-wrap'>

                                                <span id='messages'><span className='error_messages'></span> </span>
                                                <div className='contact-form-wrap'>
                                                    <div className='row'>
                                                        <div className='col-md-6'>
                                                            <div className='contact-form-input-wrap mb-3'>
                                                                <label htmlFor='conName'
                                                                       className='form-label'>*الاسم</label>
                                                                <input type='text' className='form-control' id='conName'
                                                                       name='name' aria-describedby='Name'
                                                                       onBlur={handleIncomplete}/>
                                                            </div>
                                                        </div>
                                                        <div className='col-md-6'>
                                                            <div className='contact-form-input-wrap mb-3'>
                                                                <label htmlFor='conEmail' className='form-label'>*البريد
                                                                    الإلكتروني</label>
                                                                <input type='email' className='form-control'
                                                                       name='email' id='conEmail'
                                                                       aria-describedby='emailHelp'
                                                                       onBlur={handleIncomplete}/>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className='row'>
                                                        <div className='col-md-6'>
                                                            <div className='contact-form-input-wrap mb-3'>
                                                                <label htmlFor='phoneNumber' className='form-label'>*رقم
                                                                    الهاتف</label>
                                                                <PhoneInput
                                                                    country={'sa'}
                                                                    preferredCountries={['sa', 'eg', 'ae']}
                                                                    inputProps={{
                                                                        name: 'phone',
                                                                        required: true,
                                                                        autoFocus: true
                                                                    }}
                                                                    onBlur={handleIncomplete}
                                                                    autoFormat={false}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className='col-md-6'>
                                                            <div className='contact-form-input-wrap mb-3'>
                                                                <label htmlFor='conWebsite' className='form-label'>موقع
                                                                    العيادة</label>
                                                                <input type='text' className='form-control'
                                                                       name='company' id='conWebsite'
                                                                       aria-describedby='emailHelp'
                                                                       onBlur={handleIncomplete}/>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className='contact-chooose-product'>
                                                        <p className='contact-choose-product-label'>المزيد من المعلومات
                                                            حول:</p>
                                                        <div className='contact-checkbox-wrapper'>
                                                            <div className='form-check'>
                                                                <input className='form-check-input'
                                                                       onChange={handleIncomplete} type='checkbox'
                                                                       value='قنوات حجز ذاتي جديدة'
                                                                       id='selfBookingChennels' name='products[]'/>
                                                                <label className='form-check-label'
                                                                       htmlFor='selfBookingChennels'>
                                                                    قنوات حجز ذاتي جديدة
                                                                </label>
                                                            </div>
                                                            <div className='form-check'>
                                                                <input className='form-check-input'
                                                                       onChange={handleIncomplete} type='checkbox'
                                                                       value='نظام الـحد من تغيب المرضي'
                                                                       id='noShowPrevention' name='products[]'
                                                                       defaultChecked={chkbox}/>
                                                                <label className='form-check-label'
                                                                       htmlFor='noShowPrevention'>
                                                                    نظام الـحد من تغيب المرضي
                                                                </label>
                                                            </div>
                                                            <div className='form-check'>
                                                                <input className='form-check-input'
                                                                       onChange={handleIncomplete} type='checkbox'
                                                                       value='آخر' name='products[]' id='otherProducts'/>
                                                                <label className='form-check-label'
                                                                       htmlFor='otherProducts'>
                                                                    آخر
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='form-input-wrap mb-3'>
                                                        <ReCAPTCHA
                                                            sitekey="6LdLduMoAAAAANwKk2ytDmb9xOxlZwY9_46T4UZP"
                                                        />
                                                    </div>
                                                </div>
                                                <div className='contact-form-button-wrap'>
                                                    <input type='hidden' value='0' id='ic_id' name='ic_id'/>
                                                    <button className='btn contact-form-btn final-button' id='submitbtn'
                                                            type='submit'>ارسل
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

        )
    }else {
        return (
            <div>
                <Helmet>
                    <title>كلينيك انلوك - تواصل معنا- برنامج تطوير اداء العيادات</title>
                    <meta name="description"
                          content="Minimize manual errors and enhance productivity with Clinic Unlock performance software"/>
                    <style>
                        {'.react-tel-input .selected-flag{direction: ltr'}
                    </style>
                </Helmet>
                <section className='contact-section'>
                    <div className='contact-wrapper'>
                        <div className='container'>
                            <div className='row'>
                                <div className='col-lg-12'>
                                    <div className='contact-form-main-wrap arabic'>
                                        <form id='contactForm' method='POST' onSubmit={handleSubmit}>
                                            <div className='contact-form-col-wrap'>

                                                <span id='messages'><span className='error_messages'></span> </span>
                                                <div className='contact-form-wrap'>
                                                    <div className='row'>
                                                        <div className='col-md-6'>
                                                            <div className='contact-form-input-wrap mb-3'>
                                                                <label htmlFor='conName'
                                                                       className='form-label'>*الاسم</label>
                                                                <input type='text' className='form-control' id='conName'
                                                                       name='name' aria-describedby='Name'
                                                                       onBlur={handleIncomplete}/>
                                                            </div>
                                                        </div>
                                                        <div className='col-md-6'>
                                                            <div className='contact-form-input-wrap mb-3'>
                                                                <label htmlFor='conEmail' className='form-label'>*البريد
                                                                    الإلكتروني</label>
                                                                <input type='email' className='form-control'
                                                                       name='email' id='conEmail'
                                                                       aria-describedby='emailHelp'
                                                                       onBlur={handleIncomplete}/>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className='row'>
                                                        <div className='col-md-6'>
                                                            <div className='contact-form-input-wrap mb-3'>
                                                                <label htmlFor='phoneNumber' className='form-label'>*رقم
                                                                    الهاتف</label>
                                                                <PhoneInput
                                                                    country={'sa'}
                                                                    preferredCountries={['sa', 'eg', 'ae']}
                                                                    inputProps={{
                                                                        name: 'phone',
                                                                        required: true,
                                                                        autoFocus: true
                                                                    }}
                                                                    onBlur={handleIncomplete}
                                                                    autoFormat={false}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className='col-md-6'>
                                                            <div className='contact-form-input-wrap mb-3'>
                                                                <label htmlFor='conWebsite' className='form-label'>موقع
                                                                    العيادة</label>
                                                                <input type='text' className='form-control'
                                                                       name='company' id='conWebsite'
                                                                       aria-describedby='emailHelp'
                                                                       onBlur={handleIncomplete}/>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className='contact-chooose-product'>
                                                        <p className='contact-choose-product-label'>المزيد من المعلومات
                                                            حول:</p>
                                                        <div className='contact-checkbox-wrapper'>
                                                            <div className='form-check'>
                                                                <input className='form-check-input'
                                                                       onChange={handleIncomplete} type='checkbox'
                                                                       value='قنوات حجز ذاتي جديدة'
                                                                       id='selfBookingChennels' name='products[]'/>
                                                                <label className='form-check-label'
                                                                       htmlFor='selfBookingChennels'>
                                                                    قنوات حجز ذاتي جديدة
                                                                </label>
                                                            </div>
                                                            <div className='form-check'>
                                                                <input className='form-check-input'
                                                                       onChange={handleIncomplete} type='checkbox'
                                                                       value='نظام الـحد من تغيب المرضي'
                                                                       id='noShowPrevention' name='products[]'
                                                                       defaultChecked={chkbox}/>
                                                                <label className='form-check-label'
                                                                       htmlFor='noShowPrevention'>
                                                                    نظام الـحد من تغيب المرضي
                                                                </label>
                                                            </div>
                                                            <div className='form-check'>
                                                                <input className='form-check-input'
                                                                       onChange={handleIncomplete} type='checkbox'
                                                                       value='آخر' name='products[]' id='otherProducts'/>
                                                                <label className='form-check-label'
                                                                       htmlFor='otherProducts'>
                                                                    آخر
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='form-input-wrap mb-3'>
                                                        <ReCAPTCHA
                                                            sitekey="6LdLduMoAAAAANwKk2ytDmb9xOxlZwY9_46T4UZP"
                                                        />
                                                    </div>
                                                </div>
                                                <div className='contact-form-button-wrap'>
                                                    <input type='hidden' value='0' id='ic_id' name='ic_id'/>
                                                    <button className='btn contact-form-btn final-button' id='submitbtn'
                                                            type='submit'>ارسل
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

        )
    }
}

export default ContactUs