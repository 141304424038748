import React from 'react';


const BusinessSection = () => {
    return (
        <section className='business-section arabic'>
            <div className='business-wrapper'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-6'>
                            <div className='business-wrap'>
                                <h2 className='business-heading blue'><span className='business-heading-spn'>والآن، نعمل على توسيع نطاق تركيزنا ليشمل</span><br/>الشركات المتوسطة والكبيرة.</h2>
                                <p className='business-txt clinic-fs-5 fw-4'>نحن متحمسون لإطلاق علامتنا التجارية الجديدة كلينيك انلوك <br className='hide-br-mb'/>المدعومة من فلاش ليد.</p>
                                <p className='business-txt clinic-fs-5 fw-4'>كلينيك انلوك عبارة عن مجموعة من الحلول البرمجية المصممة <br className='hide-br-mb'/>لمساعدة العيادات الخاصة الكبيرة على العمل بشكل أكثر كفاءة <br className='hide-br-mb'/>وفعالية. يوفر منتجنا للعيادات حلاً شاملاً لإدارة المواعيد والمرضى <br className='hide-br-mb'/>والتواصل.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default BusinessSection