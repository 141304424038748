import React from 'react'
import './Footer.css'
import 'bootstrap/dist/css/bootstrap.css';
import ClinicLogo from '../../assets/cliniclogo.svg';
import ClinicLogoAr from '../../assets/clinic-logo-ar.svg';

const Footer = () => {
  return (
    <footer className='footer-section arabic'>
        <div className='footer-wrapper'>
            <div className='footer-logo'><a className='' href='/en/'><img src={ClinicLogoAr} alt='Clinic Unlock' /></a></div>
            <div className='footer-link-wrap'>
                <a href='/about' className='footer-link'>عن الشركة</a>
                <a href='/privacy' className='footer-link'>سياسة الخصوصية</a>
            </div>
            <div className='footer-text clinic-fs-3 fw-4'>بواسطة <span className='flashlead fw-6'>فلاش ليد</span></div>
        </div>
    </footer>
  )
}

export default Footer