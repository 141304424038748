import React from 'react'
import CalenderIcon from '../../assets/home-images/calender-icon.svg'
import ClinicLogo from '../../assets/home-images/cliniclogo.svg'
import ClinicLogoAr from '../../assets/home-images/clinic-logo-ar.svg'
import DesignIcon from '../../assets/home-images/design-icon.svg'
import PerformanceIcon from '../../assets/home-images/performance-icon.svg'



const BenefitSection = () => {
    return (
        <>
            <section className='benefit-section'>
            <div className='benefit-wrapper'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-5'>
                            <div className='benefit-col-wrap'>
                                <div className='benefit-icon-wrap'><img src={CalenderIcon} alt='' className='benefit-icon' /></div>
                                <div className='benefit-txt-wrap'>
                                    <h2 className='benefit-heading'><img src={ClinicLogo} alt='Clinic Unlock' /></h2>
                                    <p className='benefit-txt'>The ultimate solution for <span className='fw-6'>enhanced clinic performance.</span></p>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-7'>
                            <div className='benefit-col-wrap'>
                                <div className='benefit-icon-wrap'><img src={DesignIcon} alt='Clinic Unlock' className='benefit-icon' /></div>
                                <div className='benefit-txt-wrap'>
                                    <h2 className='benefit-heading clinic-fs-1 fw-5'>Designed for growing clinics</h2>
                                    <p className='benefit-txt'>Our system is customizable, <span className='fw-6'>can seamlessly integrate</span> with your current system and will <span className='fw-6'>enhance</span> your current booking process.</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-12'>
                            <div className='benefit-col-wrap m-0'>
                                <div className='benefit-icon-wrap'><img src={PerformanceIcon} alt='' className='benefit-icon' /></div>
                                <div className='benefit-txt-wrap'>
                                    <h2 className='benefit-heading '><img src={ClinicLogo} alt='' /></h2>
                                    <p className='benefit-txt'>is a cutting-edge <span className='fw-6'>performance software</span> that streamlines the entire client booking journey, from bookings to reminders to visit reviews. It uses the best channels and the smartest touchpoints to save costs, grow revenue, and increase loyalty from an <span className='fw-6'>automated booking</span> process to <span className='fw-6'>Whatsapp marketing campaigns</span> and offers.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </section>
        </>
    )
}

export default BenefitSection
