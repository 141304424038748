import React from 'react';
import BenefitIcon1 from '../../assets/product-images/blue-benefit-icon1.webp'
import BenefitIcon2 from '../../assets/product-images/blue-benefit-icon2.webp'
import BenefitIcon3 from '../../assets/product-images/blue-benefit-icon3.webp'
import BenefitIcon4 from '../../assets/product-images/blue-benefit-icon4.webp'
import BenefitIcon5 from '../../assets/product-images/blue-benefit-icon5.webp'


const BlueBenefitSection = () => {
    return (
        <section className='book-benefit-section'>
            <div className='book-benefit-wrapper'>
                <p className='book-benefit-text'><span className='blue'>No show</span> prevention</p>
                <h3 className='book-benefit-heading'>Main benefits</h3>
                <div className='book-benefit-card-wrapper'>
                    <div className='book-benefit-card'>
                        <div className='book-benefit-card-icon-wrap'><img src={BenefitIcon1} alt='Patient Report Icon' className='book-benefit-card-icon' /></div>
                        <div className='book-benefit-card-txt'><span className='fw-7'>Predict your patient</span> <br className='' />behavior</div>
                    </div>
                    <div className='book-benefit-card'>
                        <div className='book-benefit-card-icon-wrap'><img src={BenefitIcon2} alt='Missing Slot Icon' className='book-benefit-card-icon' /></div>
                        <div className='book-benefit-card-txt'><span className='fw-7'>Decrease <br className='' />missing</span> slots</div>
                    </div>
                    <div className='book-benefit-card'>
                        <div className='book-benefit-card-icon-wrap'><img src={BenefitIcon3} alt='Growth Icon' className='book-benefit-card-icon' /></div>
                        <div className='book-benefit-card-txt'><span className='fw-7'>Grow</span> your <br className='' />sales</div>
                    </div>
                    <div className='book-benefit-card'>
                        <div className='book-benefit-card-icon-wrap'><img src={BenefitIcon4} alt='API Icon' className='book-benefit-card-icon' /></div>
                        <div className='book-benefit-card-txt'>Open <br className='' /><span className='fw-7'>API</span></div>
                    </div>
                    <div className='book-benefit-card'>
                        <div className='book-benefit-card-icon-wrap'><img src={BenefitIcon5} alt='Report Icon' className='book-benefit-card-icon' /></div>
                        <div className='book-benefit-card-txt'><span className='fw-7'>Reports</span> <br className='' />Dashboard</div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default BlueBenefitSection