import React from 'react';
import BenefitIcon1 from '../../assets/product-images/green-benefit-icon1.webp';
import BenefitIcon2 from '../../assets/product-images/green-benefit-icon2.webp';
import BenefitIcon3 from '../../assets/product-images/green-benefit-icon3.webp';
import BenefitIcon4 from '../../assets/product-images/green-benefit-icon4.webp';
import BenefitIcon5 from '../../assets/product-images/green-benefit-icon5.webp';
import BenefitIcon6 from '../../assets/product-images/green-benefit-icon6.webp';


const BookBenefitSection = () => {
    return (
        <section className='book-benefit-section'>
            <div className='book-benefit-wrapper'>
                <p className='book-benefit-text'>New <span className='green'>self booking</span> channels</p>
                <h3 className='book-benefit-heading'>Main benefits</h3>
                <div className='book-benefit-card-wrapper'>
                    <div className='book-benefit-card'>
                        <div className='book-benefit-card-icon-wrap'><img src={BenefitIcon1} alt='Calender Icon' className='book-benefit-card-icon' /></div>
                        <div className='book-benefit-card-txt'>Enhance booking <br className='hide-mb' />experience</div>
                    </div>
                    <div className='book-benefit-card'>
                        <div className='book-benefit-card-icon-wrap'><img src={BenefitIcon2} alt='Brand Icon' className='book-benefit-card-icon' /></div>
                        <div className='book-benefit-card-txt'>Enhance your <br className='hide-mb' />brand loyalty</div>
                    </div>
                    <div className='book-benefit-card'>
                        <div className='book-benefit-card-icon-wrap'><img src={BenefitIcon3} alt='Growth Icon' className='book-benefit-card-icon' /></div>
                        <div className='book-benefit-card-txt'>Grow your sales <br className='hide-mb' />channels</div>
                    </div>
                    <div className='book-benefit-card'>
                        <div className='book-benefit-card-icon-wrap'><img src={BenefitIcon4} alt='Web Link Icon' className='book-benefit-card-icon' /></div>
                        <div className='book-benefit-card-txt'> Linking with the clinic system <br className='hide-mb' />used via API</div>
                    </div>
                    <div className='book-benefit-card'>
                        <div className='book-benefit-card-icon-wrap'><img src={BenefitIcon5} alt='App CMS Icon' className='book-benefit-card-icon' /></div>
                        <div className='book-benefit-card-txt'>App CMS</div>
                    </div>
                    <div className='book-benefit-card'>
                        <div className='book-benefit-card-icon-wrap'><img src={BenefitIcon6} alt='Report Icon' className='book-benefit-card-icon' /></div>
                        <div className='book-benefit-card-txt'>Reports <br className='hide-mb' />Dashboard</div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default BookBenefitSection