import React from 'react';



const CTABottomSection = () => {
    return (
        <>
            <section className='cta-two-section arabic'>
                <div className='cta-two-wrapper'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-6'>
                                <div className='cta-two-heaing-wrap'><h3 className='cta-two-heading clinic-hs-4 fw-7 white'>هل أنت مستعد لإحداث طفرة في طريقة <br /> الحجز في عيادتك؟</h3></div>
                                <div className='cta-two-text-wrap'><p className='clinic-fs-5 fw-3 white m-0'>.احجز استشارة مجانية مع خبيرنا اليوم</p><a href='https://pro.flash-lead.com/meeting-schedule/clinic-unlock' target='_blank' className='btn clinic-fs-5 fw-6 white book-call-btn'>احجز مكالمة</a></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
            
    )
}

export default CTABottomSection