import React from 'react';
import HomeIcon from '../../assets/home-images/home-icon.svg';
import ClockIcon from '../../assets/home-images/clock-icon.svg';
import GreenArrowEn from '../../assets/home-images/green-arrow-en.svg';
import GreenArrowAr from '../../assets/home-images/green-arrow-ar.svg';
import HomePreventionImg from '../../assets/home-images/home-prevention.svg';




const PreventionSection = () => {
    return(
        <>
            <section className='prevention-section'>
                <div className='prevention-wrap'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-7'>
                                <div className='prevention-text-wrap'>
                                    <h3 className='prevention-heading'>No show prevention</h3>
                                    <p className='prevention-text fw-4'>Reduce missed appointments via smart reminders and reports</p>
                                </div>
            
                                <div className='prevention-main-cover'>
                                    <div className='prevention-cover'>
                                        <div className='prevent-icon-wrap'><img src={HomeIcon} alt='' className='prevent-icon' /></div>
                                        <div className='prevent-icon-text-wrap'>Clinic <b>lose up to 60% of potential slot</b> due to no show</div>
                                    </div>
                                    <div className='prevention-cover'>
                                        <div className='prevent-icon-wrap'><img src={ClockIcon} alt='' className='prevent-icon' /></div>
                                        <div className='prevent-icon-text-wrap'>Updated confirmation can <b>allow reselling the slot</b></div>
                                    </div>
                                </div>
                                <div className='b-chennel-link-wrap'><a href='product.php' className='b-chennel-link'>Know more <img src={GreenArrowEn} alt='' className='green-arrow-icon' /></a></div>
                            </div>
                            <div className='col-lg-5'><div className='prevention-img-wrap'><img src={HomePreventionImg} alt='' className='prevention-img' /></div></div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default PreventionSection