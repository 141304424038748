import React, {useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import ClinicLogoBlue from '../../assets/product-images/clinic-logo-blue.webp';
import Aos from 'aos';
import 'aos/dist/aos.css';

const ProductHeroSection = () => {
  useEffect(() => {
    Aos.init({duration: 2000});
  }, [])
  return (
    <section className='product-hero-section'>
        <div className='product-hero-wrap'>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-12'>
                        <h1 className='product-hero-heading blue fw-7'><span className='clinic-logo-blue'><img src={ClinicLogoBlue} alt='Clinic Unlock' className='product-unlock-heading' /></span> the ultimate solution
                            <br />for enhanced clinic performance</h1>
                        <p className='product-hero-text clinic-fs-5 fw-4'>Clinic Unlock is a cutting-edge <span className='fw-6'>performance software</span>
                            that streamlines the entire client booking journey, <br />from bookings to reminders to visit
                            reviews.</p>
                        <p className='product-hero-text clinic-fs-5 fw-4'>It uses the best channels and the smartest touchpoints
                            to save costs, grow revenue, and increase loyalty <br />from an <span className='fw-6'>automated booking</span>
                            process to <span className='fw-6'>Whatsapp marketing campaigns</span> and offers.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    
  )
}

export default ProductHeroSection