import React from 'react';
import PreventionImg1 from '../../assets/product-images/prevention-img1.webp';
import PreventionImg2 from '../../assets/product-images/prevention-img1.webp';
import PreventionImg3 from '../../assets/product-images/prevention-img1.webp';
import PreventionToggle1 from '../../assets/product-images/prevention-toggle1.webp';
import PreventionToggle2 from '../../assets/product-images/prevention-toggle2.webp';


const PreventionSection = () => {
    return (
        <section className='selfbooking-section'>
            <div className='selfbooking-wrapper'>
                <h2 className='selfbooking-heading'><span className='selfbooking-spn blue'>No show</span> prevention</h2>
                <p className='selfbooking-text'>All clinics suffer from the absence of patients from booked appointments. This is special for the clinic, <b>reducing <br className='hide-mb' /> appointments by up to 20% through smart reminders</b> and statistics for each interested client.</p>
                <div className='selfbooking-inner-wrap'>
                    <div className='prevent-col-wrap'>
                        <div className='prevent-col-img-wrap'><img src={PreventionImg1} alt='Reschedule Appointment' className='prevent-col-img prevent-col-img1' /><img src={PreventionToggle1} alt='Toggle Line' className='prevent-toggle-img1' /></div>
                        <p className='prevent-col-text'>If the patient cancels the appointment, the appointment will automatically open again in the calendar.</p>
                    </div>
                    <div className='prevent-col-wrap'>
                        <div className='prevent-col-img-wrap'><img src={PreventionImg2} alt='Reschedule Appointment by Whatsapp' className='prevent-col-img prevent-col-img2' /></div>
                        <p className='prevent-col-text'>Easy-to-program appointment reminders are sent via interactive WhatsApp to confirm/cancel or reschedule</p>
                    </div>
                    <div className='prevent-col-wrap'>
                        <div className='prevent-col-img-wrap'><img src={PreventionImg3} alt='Appointment Detail' className='prevent-col-img prevent-col-img3' /><img src={PreventionToggle2} alt='Toggle Line' className='prevent-toggle-img2' /></div>
                        <p className='prevent-col-text'>complete application to the clinic within daysAll appointments are followed by a confirmation message on WhatsApp that includes all the appointment details.</p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default PreventionSection