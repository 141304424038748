import React from 'react'
import WebsiteIcon from '../../assets/home-images/website-icon.svg';
import WhatsappIcon from '../../assets/home-images/whatsapp-icon-black.svg';
import MbAppIcon from '../../assets/home-images/app-icon1.svg';
import GreenArrowEn from '../../assets/home-images/green-arrow-en.svg';
import GreenArrowAr from '../../assets/home-images/green-arrow-ar.svg';
import HomeSelfbookingImg from '../../assets/home-images/home-selfbooking1.svg';




const BookingChennelSection = () => {
    return (
        <>
            <section className='booking-chennel-section'>
                <div className='booking-chennel-wrap'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-7'>
                                <div className='booking-chennel-text-wrap'>
                                    <h3 className='booking-chennel-heading'>New self-booking channels</h3>
                                    <p className='booking-chennel-text fw-4'>Connect with your <span className='fw-5'>audience on their preferred <br className='hide-mb' />favorite channels</span></p>
                                </div>

                                <div className='booking-chennels-main-cover'>
                                    <div className='booking-chennels-cover'>
                                        <div className='b-chennel-icon-wrap'><img src={WebsiteIcon} alt='' className='b-chennel-icon' /></div>
                                        <div className='b-chennel-icon-text-wrap'><b>Clinic Website</b></div>
                                    </div>
                                    <div className='booking-chennels-cover'>
                                        <div className='b-chennel-icon-wrap'><img src={WhatsappIcon} alt='' className='b-chennel-icon' /></div>
                                        <div className='b-chennel-icon-text-wrap'><b>Official Whatsapp</b></div>
                                    </div>
                                    <div className='booking-chennels-cover'>
                                        <div className='b-chennel-icon-wrap'><img src={MbAppIcon} alt='' className='b-chennel-icon' /></div>
                                        <div className='b-chennel-icon-text-wrap'><b>Whitelabel Clinic App</b></div>
                                    </div>
                                </div>
                                <div className='b-chennel-link-wrap'><a href='product.php' className='b-chennel-link'>Know more <img src={GreenArrowEn} alt='' className='green-arrow-icon' /></a></div>
                            </div>
                            <div className='col-lg-5'><div className='booking-chennel-img-wrap'><img src={HomeSelfbookingImg} alt='' className='booking-chennel-img' /></div></div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default BookingChennelSection
