import React from 'react';
import AboutHeroImg from '../../assets/about-images/about-hero.webp';


const AboutHeroSection = () => {
    return (
        <section className='about-hero-section arabic'>
            <div className='about-hero-wrapper'>
                <div className='container'>
                    <div className='row mb-3'>
                        <div className='col-lg-5'>
                        <h1 className='about-hero-heading blue'><span className='about-hero-heading-spn'>التكنولوجيا لديها القدرة على</span><br className=''/> دفع الأعمــال نحو <br className='hide-br-mb'/>كفاءة وإنــتاجيــة <br className='hide-br-mb'/>وربـــــح أكـــــــثــر</h1>
                        </div>
                        <div className='col-lg-7'><img src={AboutHeroImg} alt='Laptop Image' className='about-hero-img' /></div>
                    </div>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <p className='about-hero-txt clinic-fs-4 fw-4'>في كلينيك انلوك نعتقد أن التكنولوجيا لديها القدرة على دفع الأعمال نحو كفاءة وإنتاجية وربح أكثر. ولهذا السبب نقوم بتطوير حلول</p>
                            <p className='about-hero-txt clinic-fs-4 fw-4 m-0'>برمجية مبتكرة تساعد الشركات بجميع أحجامها على تحقيق النجاح.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AboutHeroSection