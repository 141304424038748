import React from 'react';


const AccordianSection = () => {
    return (
        <section className='product-faq-section'>
            <div className='faq-wrapper'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12 p-0'>
                            <h2 className='faq-heading clinic-hs-2 fw-6'>FAQS</h2>
                            <div className='accordion' id='accordionFAQ'>
                                <div className='accordion-item'>
                                    <h2 className='accordion-header' id='headingOne'>
                                        <button className='accordion-button clinic-fs-4 fw-5' type='button'
                                                data-bs-toggle='collapse' data-bs-target='#collapseOne' aria-expanded='true'
                                                aria-controls='collapseOne'>
                                            How long will it take to finish the white-label app for my clinic?
                                        </button>
                                    </h2>
                                    <div id='collapseOne' className='accordion-collapse collapse show' aria-labelledby='headingOne'
                                        data-bs-parent='#accordionFAQ'>
                                        <div className='accordion-body clinic-fs-5 fw-3'>
                                            Usually, the deployment process is 10 times faster and has 100% guaranteed deliverability. After project outline locking and contract signature, it takes up to 4 weeks to finalize app development and deployment.
                                        </div>
                                    </div>
                                </div>
                                <div className='accordion-item'>
                                    <h2 className='accordion-header' id='headingTwo'>
                                        <button className='accordion-button collapsed clinic-fs-4 fw-5' type='button'
                                                data-bs-toggle='collapse' data-bs-target='#collapseTwo' aria-expanded='false'
                                                aria-controls='collapseTwo'>
                                            How secure is my data and client information?
                                        </button>
                                    </h2>
                                    <div id='collapseTwo' className='accordion-collapse collapse' aria-labelledby='headingTwo'
                                        data-bs-parent='#accordionFAQ'>
                                        <div className='accordion-body'>
                                            Clinic Unlock takes maximum procedures to secure client data, starting from deployment on the client's private server, to data encryption, to the strict hierarchy of access from the technical team with client-side authentication.
                                        </div>
                                    </div>
                                </div>
                                <div className='accordion-item'>
                                    <h2 className='accordion-header' id='headingThree'>
                                        <button className='accordion-button collapsed clinic-fs-4 fw-5' type='button'
                                                data-bs-toggle='collapse' data-bs-target='#collapseThree' aria-expanded='false'
                                                aria-controls='collapseThree'>
                                            Can I use Clinic Unlock with my existing booking system?
                                        </button>
                                    </h2>
                                    <div id='collapseThree' className='accordion-collapse collapse' aria-labelledby='headingThree'
                                        data-bs-parent='#accordionFAQ'>
                                        <div className='accordion-body'>
                                            Yes, Clinic Unlock integrates with any existing system through an API, which will allow you to use it as a complementary system with your existing internal ERP or booking system.
                                        </div>
                                    </div>
                                </div>
                                <div className='accordion-item'>
                                    <h2 className='accordion-header' id='headingFour'>
                                        <button className='accordion-button collapsed clinic-fs-4 fw-5' type='button'
                                                data-bs-toggle='collapse' data-bs-target='#collapseFour' aria-expanded='false'
                                                aria-controls='collapseFour'>
                                            How easy and fast is it to integrate Clinic Unlock?
                                        </button>
                                    </h2>
                                    <div id='collapseFour' className='accordion-collapse collapse' aria-labelledby='headingFour'
                                        data-bs-parent='#accordionFAQ'>
                                        <div className='accordion-body'>
                                            Clinic Unlock provides all possible ways to integrate with existing systems, from documentation, tools, and flexibility. You can also use your own tech stack to integrate or use our own to help you with the integration.
                                        </div>
                                    </div>
                                </div>
                                <div className='accordion-item'>
                                    <h2 className='accordion-header' id='headingFive'>
                                        <button className='accordion-button collapsed clinic-fs-4 fw-5' type='button'
                                                data-bs-toggle='collapse' data-bs-target='#collapseFive' aria-expanded='false'
                                                aria-controls='collapseFive'>
                                            How do we price “Clinic Unlock”
                                        </button>
                                    </h2>
                                    <div id='collapseFive' className='accordion-collapse collapse' aria-labelledby='headingFive'
                                        data-bs-parent='#accordionFAQ'>
                                        <div className='accordion-body'>
                                            The pricing is tailor made to each client based on clinic size, scope of work, features, etc… Once we both agree on the project outline, we issue the proposal within 24 hours.
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default AccordianSection