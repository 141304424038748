import React from 'react';
import PreventionImg1 from '../../assets/product-images/prevention-img1.webp';
import PreventionImg2 from '../../assets/product-images/prevention-img1.webp';
import PreventionImg3 from '../../assets/product-images/prevention-img1.webp';
import PreventionToggle1 from '../../assets/product-images/prevention-toggle1.webp';
import PreventionToggle2 from '../../assets/product-images/prevention-toggle2.webp';


const PreventionSection = () => {
    return (
        <section className='selfbooking-section arabic' style={{direction: 'ltr'}}>
            <div className='selfbooking-wrapper'>
                <h2 className='selfbooking-heading'>نظام <span className='selfbooking-spn blue'>الحد من تغيب المرضي</span></h2>
                <p className='selfbooking-text'>جميع العيادات تعاني من تغيب المرضى عن المواعيد المحجوزة. هذا النظام يتيح للعيادة <b>تقليل المواعيد المفقودة <br className='hide-mb'/>بنسبة تصل إلى ٢٠%</b> عبر التذكيرات الذكية و الإحصائيات لكل عميل</p>
                <div className='selfbooking-inner-wrap'>
                    <div className='prevent-col-wrap'>
                        <div className='prevent-col-img-wrap'><img src={PreventionImg1} alt='Reschedule Appointment' className='prevent-col-img prevent-col-img1' /><img src={PreventionToggle1} alt='Toggle Line' className='prevent-toggle-img1' /></div>
                        <p className='prevent-col-text'>إذا قام المريض بإلغاء الموعد، <br className='hide-mb'/>فسيتم <b>فتح الموعد تلقائيًا</b> مرة <br className='hide-mb'/>أخرى في التقويم.</p>
                    </div>
                    <div className='prevent-col-wrap'>
                        <div className='prevent-col-img-wrap'><img src={PreventionImg2} alt='Reschedule Appointment by Whatsapp' className='prevent-col-img prevent-col-img2' /></div>
                        <p className='prevent-col-text'>يتم إرسال <b>تذكيرات سهله البرمجة</b><br className='hide-mb'/> بالموعد عبر الوتساب التفاعلي <br className='hide-mb'/>للتأكيد / إلغاء أو إعادة الجدولة </p>
                    </div>
                    <div className='prevent-col-wrap'>
                        <div className='prevent-col-img-wrap'><img src={PreventionImg3} alt='Appointment Detail' className='prevent-col-img prevent-col-img3' /><img src={PreventionToggle2} alt='Toggle Line' className='prevent-toggle-img2' /></div>
                        <p className='prevent-col-text'>جميع المواعيد تتبعها <b>رسالة <br className='hide-mb'/>تأكيد على الواتساب</b> الموعد <br className='hide-mb'/>تتضمن كافة تفاصيل الموعد.</p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default PreventionSection