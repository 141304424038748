import React from 'react';

const PassionateSection = () => {
    return (
        <section className='passionate-section arabic'>
            <div className='passionate-wrapper'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-5'>
                            <div className='nb-abt-office-wrap'>
                                <h2 className='passion-heading blue'><span className='passion-head-spn'>نحن متحمسون</span><br/> لمساعدة الشركات على النجاح.</h2>
                                <p className='passion-txt clinic-fs-5 fw-4'>نحن نؤمن بأن حلولنا البرمجية يمكن أن تحدث فرقًا حقيقيًا في حياة عملائنا. ولهذا السبب نحن ملتزمون بتزويد عملائنا بأفضل المنتجات والدعم الممكنين.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default PassionateSection