import React, {useEffect } from 'react';
import './Homepage.css';
import 'bootstrap/dist/css/bootstrap.css';
import HeroSection from './HeroSection';
import MapSection from './MapSection';
import BenefitSection from './BenefitSection';
import BookingChennelSection from './BookingChennelSection';
import PreventionSection from './PreventionSection';
import JourneySection from './JourneySection';
import CTASection from './CTASection';
import CompaniesSection from './CompaniesSection';
import ChooseSection from './ChooseSection';
import CTABottomSection from './CTABottomSection';
import Aos from 'aos';
import 'aos/dist/aos.css';
import {Helmet} from "react-helmet";

const Homepage = () => {
  useEffect(() => {
    Aos.init({duration: 2000});
  }, [])
  return (
    <div>
        <Helmet>
            <title>كلينيك انلوك - برنامج تطوير اداء العيادات</title>
            <meta name="description" content="قم بتحسين كفاءة عيادتك باستخدام برنامج الأداء الحديث | كلينيك انلوك" />
        </Helmet>
      <HeroSection />
      <MapSection />
      <BenefitSection />
      <BookingChennelSection />
      <PreventionSection />
      <JourneySection />
      <CTASection />
      <CompaniesSection />
      <ChooseSection />
      <CTABottomSection />
    </div>
    
  )
}

export default Homepage