import React from 'react';


const ProductCTASection = () => {
    return (
        <section className='cta-two-section'>
            <div className='cta-two-wrapper'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-6'>
                            <div className='cta-two-heaing-wrap'><h3 className='cta-two-heading clinic-hs-4 fw-6 white'>Do you want a tailored <br />package to suit your needs?</h3></div>
                            <div className='cta-two-text-wrap'><p className='clinic-fs-5 fw-3 white m-0'>Book a free consultation with our expert today</p><a href='https://pro.flash-lead.com/meeting-schedule/clinic-unlock' target='_blank' className='btn clinic-fs-5 fw-6 white book-call-btn'>Book a Call</a></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ProductCTASection