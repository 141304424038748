import React, {useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import Herobanner1 from '../../assets/home-images/Clinic-Herobanner1.svg';
import Herobanner1ar from '../../assets/home-images/Hero-Banner-1-txt-ar.svg';
import Herobanner2 from '../../assets/home-images/Clinic-Herobanner2.svg';
import Herobanner2ar from '../../assets/home-images/Hero-Banner-2-txt-ar.svg';
import HerobannerMb from '../../assets/home-images/Clinic-Herobanner-mb.svg';
import HerobannerMbar from '../../assets/home-images/mobile-hero-banner-ar.svg';
import Aos from 'aos';
import 'aos/dist/aos.css';

const HeroSection = () => {
  useEffect(() => {
    Aos.init({duration: 2000});
  }, [])
  return (
    <>
      <section className='hero-section arabic'>
        <div className='hero-banner-wrap'>
            <div id='bannerSliderIndicators' className='carousel slide' data-bs-ride='carousel'>
                <div className='carousel-indicators banner-slider'>
                    <button type='button' data-bs-target='#bannerSliderIndicators' data-bs-slide-to='0' className='active' aria-current='true' aria-label='Slide 1'></button>
                    <button type='button' data-bs-target='#bannerSliderIndicators' data-bs-slide-to='1' aria-label='Slide 2'></button>
                </div>
                <div className='carousel-inner'>
                    <div className='carousel-item active'>
                        <img src={Herobanner1ar} className='home-hero-banner hide-mb' alt='...' />
                        <img src={HerobannerMbar} className='home-hero-banner only-mob' alt='...' />
                    </div>
                    <div className='carousel-item'>
                        <img src={Herobanner2ar} className='home-hero-banner hide-mb' alt='...' />
                        <img src={HerobannerMbar} className='home-hero-banner only-mob' alt='...' />
                    </div>
                </div>
                <button className='carousel-control-prev' type='button' data-bs-target='#bannerSliderIndicators' data-bs-slide='prev'>
                    <span className='carousel-control-prev-icon' aria-hidden='true'></span>
                    <span className='visually-hidden'>Previous</span>
                </button>
                <button className='carousel-control-next' type='button' data-bs-target='#bannerSliderIndicators' data-bs-slide='next'>
                    <span className='carousel-control-next-icon' aria-hidden='true'></span>
                    <span className='visually-hidden'>Next</span>
                </button>
            </div>
        </div>
      </section>
    </>
  )
}

export default HeroSection