import React from 'react';
import CheckIcon from '../../assets/product-images/green-check.webp';


const PricingSection = () => {
    return (
        <section className='product-pricing-section arabic'>
            <div className='product-pricing-wrapper'>
                <h3 className='pricing-heading' style={{letterSpacing: '0'}}>الأسعــــــــار</h3>
                <div className='pricing-card-wrapper'>
                    <div className='pricing-card'>
                        <div className='pricing-card-txt-wrap'>
                            <div className='pricing-card-txt'>قنوات حجز ذاتي جديدة</div>
                            <div className='pricing-card-heading'>حجز الموظف والموقع</div>
                        </div>
                        <div className='pricing-card-start'>أسعار تبدأ من</div>
                        <div className='pricing-card-price mb-4'>3500 ريال سعودي <span className='pricing-card-price-spn'>/سنويا</span></div>
                        <div className='pricing-card-benefit-wrap'>
                            <p className='pricing-benefit-txt'>الــمــزايــا</p>
                            <ul className='pricing-benefit-ul'>
                                <li className='pricing-benefit-li'><span className='pricing-ul-spn'><img src={CheckIcon} alt='Green Check Icon' className='pricing-li-icon'/></span> صفحة حجز الويب المضمنة</li>
                                <li className='pricing-benefit-li'><span className='pricing-ul-spn'><img src={CheckIcon} alt='Green Check Icon' className='pricing-li-icon'/></span> لوحة تحكم الموظف لحجوزات مركز الاتصال</li>
                                <li className='pricing-benefit-li'><span className='pricing-ul-spn'><img src={CheckIcon} alt='Green Check Icon' className='pricing-li-icon'/></span> فرع واحد</li>
                                <li className='pricing-benefit-li'><span className='pricing-ul-spn'><img src={CheckIcon} alt='Green Check Icon' className='pricing-li-icon'/></span> ما يصل إلى 10 موظفين حجز</li>
                                <li className='pricing-benefit-li'><span className='pricing-ul-spn'><img src={CheckIcon} alt='Green Check Icon' className='pricing-li-icon'/></span> ما يصل إلى 10 اطباء</li>
                                <li className='pricing-benefit-li'><span className='pricing-ul-spn'><img src={CheckIcon} alt='Green Check Icon' className='pricing-li-icon'/></span> طبيب اضافي 150-200 ريال</li>
                                <li className='pricing-benefit-li'><span className='pricing-ul-spn'><img src={CheckIcon} alt='Green Check Icon' className='pricing-li-icon'/></span> الموظف الاضافي 50 ريال</li>
                                <li className='pricing-benefit-li'><span className='pricing-ul-spn'><img src={CheckIcon} alt='Green Check Icon' className='pricing-li-icon'/></span> يتضمن تأكيد Whatsapp (ما يصل إلى 1000 رسالة شهريًا)</li>
                            </ul>
                        </div>
                        <div className='pricing-card-btn-wrap'><a href='https://pro.flash-lead.com/meeting-schedule/clinic-unlock' className='btn product-pricing-btn'>Start Now</a></div>

                    </div>
                    <div className='pricing-card pricing-second-card'>
                        <div className='pricing-card-txt-wrap'>
                            <div className='pricing-card-txt'>قنوات حجز ذاتي جديدة</div>
                            <div className='pricing-card-heading'>الــــموظف/المــوقع/<br/>التطبيق/واتـــــــساب</div>
                        </div>
                        <div className='pricing-card-start'>أسعار تبدأ من</div>
                        <div className='pricing-card-price mb-4'>5500 ريال سعودي <span className='pricing-card-price-spn'>/سنويا</span></div>
                        <div className='pricing-card-benefit-wrap'>
                            <p className='pricing-benefit-txt'>الــمــزايــا</p>
                            <ul className='pricing-benefit-ul'>
                                <li className='pricing-benefit-li'><span className='pricing-ul-spn'><img src={CheckIcon} alt='Green Check Icon' className='pricing-li-icon'/></span> تطبيق IOS/Android وايت ليبل</li>
                                <li className='pricing-benefit-li'><span className='pricing-ul-spn'><img src={CheckIcon} alt='Green Check Icon' className='pricing-li-icon'/></span> واتس اب مبرمج</li>
                                <li className='pricing-benefit-li'><span className='pricing-ul-spn'><img src={CheckIcon} alt='Green Check Icon' className='pricing-li-icon'/></span> صفحة حجز الويب المضمنة</li>
                                <li className='pricing-benefit-li'><span className='pricing-ul-spn'><img src={CheckIcon} alt='Green Check Icon' className='pricing-li-icon'/></span> لوحة تحكم الموظف لحجوزات مركز الاتصال</li>
                                <li className='pricing-benefit-li'><span className='pricing-ul-spn'><img src={CheckIcon} alt='Green Check Icon' className='pricing-li-icon'/></span> فرع واحد</li>
                                <li className='pricing-benefit-li'><span className='pricing-ul-spn'><img src={CheckIcon} alt='Green Check Icon' className='pricing-li-icon'/></span> ما يصل إلى 10 موظفين حجز</li>
                                <li className='pricing-benefit-li'><span className='pricing-ul-spn'><img src={CheckIcon} alt='Green Check Icon' className='pricing-li-icon'/></span> ما يصل إلى 10 اطباء</li>
                                <li className='pricing-benefit-li'><span className='pricing-ul-spn'><img src={CheckIcon} alt='Green Check Icon' className='pricing-li-icon'/></span> طبيب اضافي 150 ريال</li>
                                <li className='pricing-benefit-li'><span className='pricing-ul-spn'><img src={CheckIcon} alt='Green Check Icon' className='pricing-li-icon'/></span> الموظف الاضافي 50 ريال</li>
                                <li className='pricing-benefit-li'><span className='pricing-ul-spn'><img src={CheckIcon} alt='Green Check Icon' className='pricing-li-icon'/></span> يتضمن تأكيد Whatsapp (ما يصل إلى 1000 رسالة شهريًا)</li>
                            </ul>
                        </div>
                        <div className='pricing-card-btn-wrap'><a href='https://pro.flash-lead.com/meeting-schedule/clinic-unlock' className='btn product-pricing-btn'>Start Now</a></div>

                    </div>
                    <div className='pricing-card'>
                        <div className='pricing-card-txt-wrap'>
                            <div className='pricing-card-txt'>نظام الحد من تغيب المرضي</div>
                            <div className='pricing-card-heading'>منظم تغيب المرضي</div>
                        </div>
                        <div className='pricing-card-start'>أسعار تبدأ من</div>
                        <div className='pricing-card-price mb-4'>3500 ريال سعودي <span className='pricing-card-price-spn'>/سنويا</span></div>
                        <div className='pricing-card-benefit-wrap'>
                            <p className='pricing-benefit-txt'>الــمــزايــا</p>
                            <ul className='pricing-benefit-ul'>
                                <li className='pricing-benefit-li'><span className='pricing-ul-spn'><img src={CheckIcon} alt='Green Check Icon' className='pricing-li-icon'/></span> مبرمج التذكيرات</li>
                                <li className='pricing-benefit-li'><span className='pricing-ul-spn'><img src={CheckIcon} alt='Green Check Icon' className='pricing-li-icon'/></span>تأكيد Whatsapp API</li>
                                <li className='pricing-benefit-li'><span className='pricing-ul-spn'><img src={CheckIcon} alt='Green Check Icon' className='pricing-li-icon'/></span> شات بوت الدردشة التفاعلي</li>
                                <li className='pricing-benefit-li'><span className='pricing-ul-spn'><img src={CheckIcon} alt='Green Check Icon' className='pricing-li-icon'/></span> ما يصل إلى 800 موعد شهرياً</li>
                                <li className='pricing-benefit-li'><span className='pricing-ul-spn'><img src={CheckIcon} alt='Green Check Icon' className='pricing-li-icon'/></span> كل 200 موعد إضافي 200 ريال</li>
                                <li className='pricing-benefit-li'><span className='pricing-ul-spn'><img src={CheckIcon} alt='Green Check Icon' className='pricing-li-icon'/></span> التأكيد الآلي</li>
                                <li className='pricing-benefit-li'><span className='pricing-ul-spn'><img src={CheckIcon} alt='Green Check Icon' className='pricing-li-icon'/></span> فتح API بين الأدوات و البرامج</li>
                                <li className='pricing-benefit-li'><span className='pricing-ul-spn'><img src={CheckIcon} alt='Green Check Icon' className='pricing-li-icon'/></span> ما يصل إلى 10 موظفين حجز</li>
                            </ul>
                        </div>
                        <div className='pricing-card-btn-wrap'><a href='https://pro.flash-lead.com/meeting-schedule/clinic-unlock' className='btn product-pricing-btn'>Start Now</a></div>

                    </div>
                </div>
            </div>
        </section>
    )
}

export default PricingSection