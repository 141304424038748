import React, {useEffect } from 'react';
import './Productpage.css';
import 'bootstrap/dist/css/bootstrap.css';
import ProductHeroSection from './ProductHeroSection';
import SelfBookingSection from './SelfBookingSection';
import SFReasonSection from './SFReasonSection';
import BookBenefitSection from './BookBenefitSection';
import PreventionSection from './PreventionSection';
import PreventionReasonSection from './PreventionReasonSection';
import BlueBenefitSection from './BlueBenefitSection';
import PricingSection from './PricingSection';
import ProductCTASection from './ProductCTASection';
import AccordianSection from './AccordianSection';
import {Helmet} from "react-helmet";
import Aos from 'aos';
import 'aos/dist/aos.css';

const Productpage = () => {
  useEffect(() => {
    Aos.init({duration: 2000});
  }, [])
  return (
    <div>
        <Helmet>
            <title>Clinic Unlock – The Ultimate Solution for Clinic Efficiency</title>
            <meta name="description" content="Minimize manual errors and enhance productivity with Clinic Unlock performance software" />
        </Helmet>
      <ProductHeroSection />
      <SelfBookingSection />
      <SFReasonSection />
      <BookBenefitSection />
      <PreventionSection />
      <PreventionReasonSection />
      <BlueBenefitSection />
      <PricingSection />
      <ProductCTASection />
      <AccordianSection />
    </div>
    
  )
}

export default Productpage